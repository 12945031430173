import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from 'types/polymorphic';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import cx from 'classnames';
import CircularProgress from '../../CircularProgress';
import { useClassnames } from 'shared/useClassnames';
import styles from './ButtonRefresh.module.scss';

export type BaseButtonProps = {
  size?: 'large' | 'small';
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  topIcon?: ReactNode;
  children?: ReactNode;
  classes?: Partial<typeof styles>;
  fullWidth?: boolean;
} & (
  | {
      variant?:
        | 'primary'
        | 'secondary'
        | 'secondary-outline'
        | 'secondary-icon'
        | 'transparent';
    }
  | {
      variant: 'colored';
      color?: string;
    }
);

export type ButtonProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, BaseButtonProps>;

export type DEFAULT_COMPONENT = 'button';
export const DEFAULT_COMPONENT = 'button';

export type ButtonComponent = <C extends React.ElementType = DEFAULT_COMPONENT>(
  props: ButtonProps<C>
) => React.ReactElement | null;

// eslint-disable-next-line react/display-name
const Button: ButtonComponent = React.forwardRef(
  <C extends React.ElementType = DEFAULT_COMPONENT>(
    {
      component,
      className,
      variant = 'secondary',
      size = 'large',
      disabled = false,
      selected = false,
      fullWidth = false,
      loading = false,
      leftIcon,
      rightIcon,
      topIcon,
      children,
      classes = {},
      color,
      ...props
    }: ButtonProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Root = component || DEFAULT_COMPONENT;
    const classNames = useClassnames(styles, classes);
    const isColored = useMemo(() => variant === 'colored', [variant]);

    return (
      <Root
        className={cx(
          classNames.root,
          {
            [classNames.primary]: variant === 'primary',
            [classNames.secondary]: variant === 'secondary',
            [classNames.secondaryOutline]: variant === 'secondary-outline',
            [classNames.secondaryIcon]: variant === 'secondary-icon',
            [classNames.transparent]: variant === 'transparent',
            [classNames.colored]: isColored,
            [classNames.large]:
              size === 'large' && variant !== 'secondary-icon',
            [classNames.small]:
              size === 'small' && variant !== 'secondary-icon',
            [classNames.withTopIcon]: Boolean(topIcon),
            [classNames.disabled]: disabled,
            [classNames.selected]: selected,
            [classNames.loading]: loading,
            [classNames.fullWidth]: fullWidth,
            [classNames.contentEmpty]: !children,
          },
          className
        )}
        disabled={disabled || loading}
        style={
          isColored && color
            ? ({ '--color': color } as CSSProperties)
            : undefined
        }
        {...props}
        ref={ref}
      >
        {topIcon && (
          <div className={cx(classNames.icon, classNames.topIcon)}>
            {topIcon}
          </div>
        )}
        {leftIcon && !topIcon && (
          <div className={cx(classNames.icon, classNames.leftIcon)}>
            {leftIcon}
          </div>
        )}
        {children && <div className={cx(classNames.content)}>{children}</div>}
        {rightIcon && !topIcon && (
          <div className={cx(classNames.icon, classNames.rightIcon)}>
            {rightIcon}
          </div>
        )}
        {loading && (
          <CircularProgress
            className={classNames.circularProgress}
            size={16.67}
          />
        )}
      </Root>
    );
  }
);

export default Button;
