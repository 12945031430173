import React, { forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react';
import cx from 'classnames';
import styles from './Backdrop.module.scss';
import mergeClasses from 'shared/mergeClasses';

export type BackdropProps = HTMLAttributes<HTMLElement> & {
  opened?: boolean;
  className?: string;
  children?: ReactNode;
  classes?: typeof styles;
};

export type BackdropRef = HTMLDivElement;

const Backdrop = forwardRef<BackdropRef, BackdropProps>(
  ({ opened, className, children, classes = {}, ...props }, ref) => {
    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);
    return (
      <div
        ref={ref}
        className={cx(
          classNames.root,
          {
            [classNames.opened]: opened,
          },
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Backdrop.displayName = 'Backdrop';

export default Backdrop;
