import React, { forwardRef, HTMLAttributes, useMemo } from 'react';
import cx from 'classnames';
import styles from './CircularProgress.module.scss';
import mergeClasses from 'shared/mergeClasses';

type CircularProgressClasses = Omit<typeof styles, 'rotate' | 'dash'>;

export type CircularProgressProps = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  classes?: CircularProgressClasses;
  size?: number;
  thickness?: number;
  color?: 'primary' | 'secondary';
};

const CircularProgress = forwardRef<HTMLDivElement, CircularProgressProps>(
  ({ classes = {}, size = 20, thickness = 1.8, className, color }, ref) => {
    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);

    return (
      <div
        ref={ref}
        className={cx(classNames.root, className, {
          [classNames.primary]: color === 'primary',
          [classNames.secondary]: color === 'secondary',
        })}
        style={{
          width: size,
          height: size,
        }}
      >
        <svg
          className={classNames.svg}
          viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
          width={size}
          height={size}
        >
          <circle
            className={classNames.circle}
            cx={size}
            cy={size}
            r={(size - thickness) / 2}
            fill="none"
            strokeWidth={thickness}
          />
        </svg>
      </div>
    );
  }
);

CircularProgress.displayName = 'CircularProgress';

export default CircularProgress;
