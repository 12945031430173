import React, {
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import cx from 'classnames';
import { useClassnames } from '../../shared/useClassnames';
import styles from './Navigation.module.scss';
import { LogoIcon, LogoLightIcon, BurgerIcon } from 'icons';
import Tabs from '../Tabs';
import Tab, { TabProps } from '../Tabs/Tab';
import { Button } from '@flatfy/supernova-new';
import { NavigationSidebarProps } from 'components/NavigationSidebar';
import { NavigationCitySelectProps } from 'components/NavigationCitySelect';
import FavoriteIcon from '../../icons/favorite.svg';
import { DataAttributes } from 'types/base';

export type NavigationLinkItem = {
  value: string;
  content: ReactNode;
  href: string;
  withBadge?: boolean;
};

export type NavigationProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onClick'
> & {
  /** <a href="#link-item">NavigationLinkItem</a> */
  links: NavigationLinkItem[];
  value?: string;
  onClick?: (value: string, event?: MouseEvent) => void;
  className?: string;
  classes?: typeof styles;
  logoLink?: string;
  logo?: ReactNode;
  LogoOptions?: DataAttributes & Partial<HTMLHyperlinkElementUtils>;
  /** <a href="/tabs#tab-props" target="_blank">TabProps</a> */
  TabOptions?: DataAttributes & Partial<TabProps<'a'>>;
  /** <a href="/navigation-sidebar target="_blank">NavigationSidebar</a> */
  SidebarElement?: ReactElement<NavigationSidebarProps>;
  /** <a href="/navigation-city-select" target="_blank">NavigationCitySelect</a> */
  CitySelectElement?: ReactElement<NavigationCitySelectProps>;
  badgeIcon?: ReactNode;
  dark?: boolean;
};

const Navigation = forwardRef<HTMLDivElement, NavigationProps>(
  (
    {
      links,
      value,
      onClick = () => {},
      className,
      classes = {},
      logoLink,
      dark,
      logo = dark ? <LogoLightIcon /> : <LogoIcon />,
      LogoOptions = {},
      TabOptions = {},
      SidebarElement,
      CitySelectElement,
      badgeIcon = <FavoriteIcon />,
      ...props
    },
    ref
  ) => {
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const classNames = useClassnames(styles, classes);

    return (
      <>
        <nav
          ref={ref}
          className={cx(classNames.root, className, {
            [classNames.dark]: dark,
          })}
          {...props}
        >
          <div className={classNames.wrapper}>
            <a href={logoLink} className={classNames.logo} {...LogoOptions}>
              {logo}
            </a>
            {CitySelectElement}
            <div className={classNames.links}>
              <Tabs
                value={value}
                className={classNames.tabs}
                forDarkBackground={dark}
              >
                {links.map(({ content, withBadge, ...link }) => (
                  <Tab
                    key={link.value}
                    onClick={onClick}
                    selected={link.value === value}
                    component="a"
                    {...link}
                    {...TabOptions}
                  >
                    {content}
                    {withBadge && (
                      <div className={styles.badge}>{badgeIcon}</div>
                    )}
                  </Tab>
                ))}
              </Tabs>
            </div>

            <div className={classNames.actions}>
              <Button
                icon={<BurgerIcon />}
                onClick={() => setSidebarOpened(true)}
                variant="ghost-dark"
                size="small"
                classes={{
                  root: classNames.burgerButton,
                  icon: classNames.burgerButtonIcon,
                }}
              />
            </div>
          </div>
        </nav>
        {SidebarElement &&
          React.cloneElement(SidebarElement, {
            onClose: () => setSidebarOpened(false),
            opened: sidebarOpened,
          })}
      </>
    );
  }
);

Navigation.displayName = 'Navigation';

export default Navigation;
