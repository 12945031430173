import React, { useState, useEffect } from "react";
import cx from "classnames";
import { attachGaDataAttributes } from "../../utils/analytics";

const UICheckbox = ({
  content,
  checked,
  value,
  event,
  onChange,
  className,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e) => {
    setIsChecked(!isChecked);
    onChange(e);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label
      className={cx("UICheckbox", { "-disabled": disabled }, className)}
      {...attachGaDataAttributes(event)}
    >
      <input
        type="checkbox"
        checked={isChecked}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <span className="UICheckbox-custom" />
      <div className="UICheckbox-content">{content}</div>
    </label>
  );
};

export default UICheckbox;
