import React, { ReactNode, useMemo } from 'react';
import cx from 'classnames';
import { AppLinksType, APP_LINKS_DATA } from './data';
import styles from './AppLinks.module.scss';

export type AppLinkProps = {
  variant: 'android' | 'ios';
  text?: ReactNode;
  className?: string;
};

export const AppLink = ({ variant, text, className }: AppLinkProps) => {
  const data = useMemo(() => APP_LINKS_DATA[variant], [variant]);
  return (
    <a href={data.href} className={cx(styles.link, className)} target="_blank">
      <div className={styles.logo}>{data.logoIcon}</div>
      <div className={styles.content}>
        <span>{text}</span>
        {data.textIcon}
      </div>
    </a>
  );
};

export type AppLinksProps = {
  className?: string;
  classes?: typeof styles;
  downloadTranslation: ReactNode;
  children: ReactNode;
};

export const AppLinks = ({
  className,
  classes = {},
  children,
  downloadTranslation,
}: AppLinksProps) => {
  const childrenArray = React.Children.toArray(children);

  const items = childrenArray.map(child => {
    if (!React.isValidElement(child)) return null;

    return React.cloneElement(child as React.ReactElement<AppLinkProps>, {
      className: classes.link,
      text: downloadTranslation,
    });
  });
  return (
    <div className={cx(styles.root, classes.root, className)}>{items}</div>
  );
};
