import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { IEvent } from "../../types/event";
import { attachGaDataAttributes } from "../../utils/analytics";

const ERROR_TYPES = {
  valueMissing: "valueMissing",
  typeMismatch: "typeMismatch",
  patternMismatch: "patternMismatch",
  tooLong: "tooLong",
};

class UIInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusStartTime: null,
      focused: false,
      validity: { valid: true },
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.inputRef.current.value = typeof value === "undefined" ? null : value;
    }
  }

  getTagSettings() {
    const {
      name,
      placeholder,
      type,
      value,
      isTextarea,
      attributes,
      isPhone,
      event,
      required,
    } = this.props;

    const base = {
      name,
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      onBlur: this.handleFocusOut,
      value,
      ref: this.inputRef,
      id: name,
      placeholder,
      required,
      ...attributes,
      ...attachGaDataAttributes(event),
    };

    if (isTextarea)
      return {
        tagName: "textarea",
        props: base,
      };

    if (isPhone)
      return {
        tagName: PhoneInput,
        props: {
          ...base,
          onChange: this.handlePhoneChange,
          defaultCountry: "UA",
          title: "+XXXXXXXXXXXX",
        },
      };
    return {
      tagName: "input",
      props: {
        ...base,
        type,
        id: name,
      },
    };
  }

  getValidationValueMissing(e) {
    const target = e || this.inputRef.current;
    return (
      target.required && (!this.props.value || this.props.value.length === 0)
    );
  }

  getValidity(e) {
    const target = e || this.inputRef.current;
    const { validity } = target;
    const res = {
      valid: validity.valid,
    };
    Object.keys(ERROR_TYPES).forEach((err) => {
      res[err] = validity[err];
    });
    if (this.props.isPhone) {
      const isValid =
        (!target.required &&
          (!this.props.value || this.props.value.length === 0)) ||
        isValidPhoneNumber(this.props.value);
      if (!isValid) {
        res.valid = false;
        res[ERROR_TYPES.patternMismatch] = true;
      }
    }
    return res;
  }

  focus = () => {
    this.inputRef.current.focus();
  };

  validate() {
    if (!this.inputRef.current) return true;
    const validity = this.getValidity();
    this.setValidity(validity);
    this.props.onValidChange(validity.valid);
    return validity;
  }

  resetValidation() {
    this.setValidity({ valid: true });
  }

  clearValidation() {
    this.setValidity({ valid: true });
    this.props.onValidChange(true);
  }

  handleChange = (e) => {
    this.props.onChange(this.props.name, e.target.value);
    if (!this.state.validity.valid) {
      const validity = this.getValidity(e.target);
      this.setValidity(validity);
      this.props.onValidChange(validity.valid);
    }
  };

  handlePhoneChange = (value) => {
    this.props.onChange(this.props.name, value);
  };

  handleFocus = () => {
    this.setState((prevState) => ({
      ...prevState,
      focused: true,
      focusStartTime: new Date().valueOf(),
    }));
  };

  handleFocusOut = () => {
    const focusOutTime = new Date().valueOf();
    this.props.onFocus({
      focusOutTime,
      fillingTime: focusOutTime - this.state.focusStartTime,
    });
    this.setState((prevState) => ({
      ...prevState,
      focused: false,
      focusStartTime: null,
    }));
  };

  getErrorType() {
    const { validity } = this.state;
    if (validity[ERROR_TYPES.valueMissing]) return "required";
    if (
      validity[ERROR_TYPES.typeMismatch] ||
      validity[ERROR_TYPES.patternMismatch]
    ) {
      switch (this.props.type) {
        case "email":
          return "typeEmail";
        case "phone":
        case "tel":
          return "typePhone";
        default:
          return "typeCommon";
      }
    }
    if (validity[ERROR_TYPES.tooLong]) return "tooLong";
    return "common";
  }

  setValidity(value) {
    this.setState(
      (prevState) => ({
        ...prevState,
        validity: value,
      }),
      () => {
        if (!value.valid) {
          this.props.onError(this.getErrorType());
        }
      }
    );
  }

  render() {
    const {
      name,
      label,
      value,
      customClassName,
      disabled,
      isTextarea,
      isPhone,
      icon,
      classes,
    } = this.props;
    const { validity, focused } = this.state;

    const isError = !validity.valid || this.props.isCustomError;
    const tagSettings = this.getTagSettings();

    return (
      <div className={cx("UIInput", customClassName, classes.root)}>
        <div
          className={cx("UIInput-field", classes.field, {
            "-filled": value && value.length > 0,
            "-active": focused,
            "-error": isError,
            "-disabled": disabled,
            "-textarea": isTextarea,
            "-phone": isPhone,
          })}
        >
          {icon && (
            <div className={cx("UIInput-icon", classes.icon)}>{icon}</div>
          )}
          <tagSettings.tagName
            {...tagSettings.props}
            className={cx("UIInput-input", classes.input)}
          />
          {label && (
            <label
              htmlFor={name}
              className={cx("UIInput-label", classes.label)}
            >
              {label}
            </label>
          )}
        </div>
        {isError && this.props.renderError && (
          <div className={cx("UIInput-error", classes.error)}>
            {this.props.renderError(this.getErrorType())}
          </div>
        )}
      </div>
    );
  }
}

const IInputClasses = {
  root: PropTypes.string,
  field: PropTypes.string,
  icon: PropTypes.string,
  input: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

UIInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  isTextarea: PropTypes.bool,
  customClassName: PropTypes.string,
  attributes: PropTypes.object,
  isPhone: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onValidChange: PropTypes.func,
  renderError: PhoneInput.func,
  isCustomError: PropTypes.bool,
  event: PropTypes.shape(IEvent).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onError: PropTypes.func,
  icon: PropTypes.element,
  classes: PropTypes.shape(IInputClasses),
};

UIInput.defaultProps = {
  type: "text",
  onFocus: () => {},
  onValidChange: () => {},
  onError: () => {},
  attributes: {},
  classes: {},
};

export default UIInput;
