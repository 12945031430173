import { Classes } from 'types/base';
import cx from 'classnames';

function mergeClasses<T extends Classes>(
  baseClasses: T,
  additionalClasses: Partial<T>
): T {
  const merged = Object.entries(baseClasses).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: cx(value, additionalClasses[key]),
    };
  }, {});

  return merged as T;
}

export default mergeClasses;
