import React from "react";

export default () => (
  <svg
    className="ico arrow-forward"
    width="20"
    height="20"
    fill="#505050"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 3.333L8.825 4.508l4.65 4.659H3.333v1.667h10.142l-4.65 4.658L10 16.667 16.666 10 10 3.333z" />
  </svg>
);
