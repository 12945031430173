import { useMemo } from "react";

export type FontFamilyValue = {
  roboto: string;
  inter: string;
};

export type FontFamily = {
  values: FontFamilyValue;
  css: {
    '--robotoFont': string;
    '--interFont': string;
  };
};

export const DefaultFontFamily: FontFamilyValue = {
  roboto: 'Roboto',
  inter: 'Inter',
};


const useFontFamily = (themeValue: Partial<FontFamilyValue> = DefaultFontFamily) => {
  const composedValue = useMemo(() => ({
    ...DefaultFontFamily,
    ...themeValue,
  }), [themeValue]);

  const css = useMemo(() => {
    return Object.entries(composedValue).reduce(
      (prev, [key, value]) => ({ ...prev, [`--${key}Font`]: value }),
      {}
    );
  }, [composedValue])

  return {
    values: composedValue,
    css: css,
  }
};

export default useFontFamily;
