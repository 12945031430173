import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { SelectProps } from '.';

export type SelectContextValue = {
  selected: any | any[];
  onSelect: (value: any | any[]) => void;
  opened: boolean;
  onOpenedChange: (value: boolean) => void;
  onItemClick: (node: React.ReactElement<any>) => void;
};

//@ts-ignore
export const SelectContext = createContext<SelectContextValue>(null);

export const SelectProvider = ({
  multiple,
  value: controlledSelected,
  opened: controlledOpened = false,
  onChange = () => {},
  children,
  closeOnChange = true,
  onOpenedChange = () => {},
  onClose = () => {},
  onOpen = () => {},
}: SelectProps & { children: ReactNode }) => {
  const [selected, setSelected] = useState(
    controlledSelected || (multiple ? [] : controlledSelected)
  );
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (selected === controlledSelected) return;
    const nextValue =
      controlledSelected || (multiple ? [] : controlledSelected);
    setSelected(nextValue);
  }, [controlledSelected]);

  useEffect(() => {
    if (opened === controlledOpened) return;
    setOpened(controlledOpened);
  }, [controlledOpened]);

  const handleSelectedChange = (value: any) => {
    const nextValue = value || (multiple ? [] : value);
    setSelected(nextValue);
    onChange(nextValue);
  };

  const handleOpenedChange = (value: any) => {
    setOpened(value);
    onOpenedChange(value);
    if (value) {
      onOpen();
    } else {
      onClose();
    }
  };

  const handleItemClick = (node: React.ReactElement<any>) => {
    if (multiple) {
      if (selected.includes(node.props.value)) {
        handleSelectedChange(
          selected.filter(
            (e: any) => e.toString() !== node.props.value.toString()
          )
        );
      } else {
        handleSelectedChange([...selected, node.props.value]);
      }
    } else {
      handleSelectedChange(node.props.value);
    }
    if (closeOnChange) {
      handleOpenedChange(false);
    }
  };

  return (
    <SelectContext.Provider
      value={{
        selected,
        onSelect: handleSelectedChange,
        onItemClick: handleItemClick,
        opened,
        onOpenedChange: handleOpenedChange,
      }}
    >
      {children}
    </SelectContext.Provider>
  );
};
