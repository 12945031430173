import React, {
  ElementType,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from 'react';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';
import styles from './MenuRefresh.module.scss';

export type MenuSubheaderProps = HTMLAttributes<HTMLElement> & {
  children?: ReactNode;
  className?: string;
  component?: ElementType;
  title?: string;
  classes?: typeof styles;
};

const MenuSubheader = forwardRef<any, MenuSubheaderProps>(
  (
    {
      children,
      title,
      className,
      component: Root = 'div',
      classes = {},
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);

    return (
      <Root
        className={cx(classNames.subheader, className)}
        ref={ref}
        {...props}
      >
        {React.isValidElement(children) && children.props.withCheckbox
          ? React.cloneElement(children as React.ReactElement, {
              className: classNames.menuItem,
            })
          : children}
      </Root>
    );
  }
);

MenuSubheader.displayName = 'MenuSubheaderRefresh';

export default MenuSubheader;
