import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';
import FavoriteIcon from '../../../icons/favorite.svg';
import Image from 'components/Image';
import styles from './BottomNavigationProfileIcon.module.scss';

export type BottomNavigationProfileIconProps = HTMLAttributes<HTMLElement> & {
  avatarUrl?: string;
  userLetter?: string;
  withUserData?: boolean;
  withBadge?: boolean;
  defaultIcon: ReactNode;
  badgeIcon?: ReactNode;
  className?: string;
  classes?: typeof styles;
};

const BottomNavigationProfileIcon = forwardRef<
  HTMLDivElement,
  BottomNavigationProfileIconProps
>(
  (
    {
      classes = {},
      className,
      badgeIcon = <FavoriteIcon />,
      withBadge = false,
      withUserData = false,
      userLetter = 'U',
      avatarUrl,
      defaultIcon,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <div className={cx(classNames.root, className)} {...props} ref={ref}>
        {withUserData ? (
          <div className={classNames.profile}>
            {avatarUrl ? <Image src={avatarUrl} alt="" /> : userLetter}
          </div>
        ) : (
          defaultIcon
        )}
        {withBadge && <div className={classNames.badge}>{badgeIcon}</div>}
      </div>
    );
  }
);

BottomNavigationProfileIcon.displayName = 'BottomNavigationProfileIcon';

export default BottomNavigationProfileIcon;
