const hexToRgb = (hex: `#${string}`): number[] | [] => {
  const hexNumbersArray = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_, r, g, b) => "#" + r + r + g + g + b + b
    ).substring(1).match(/.{2}/g);

  if (!hexNumbersArray) {
    return [];
  }

  return hexNumbersArray.map((x) => parseInt(x, 16));
}

export default hexToRgb;
