import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import styles from './Footer.module.scss';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';

export type FooterLinkProps = Partial<HTMLHyperlinkElementUtils> &
  HTMLAttributes<HTMLElement> & {
    icon?: ReactNode;
    className?: string;
  };

export type FooterLinksBlockProps = Omit<
  HTMLAttributes<HTMLElement>,
  'title'
> & {
  /** <a href="#link">FooterLinkProps</a> */
  links?: FooterLinkProps[];
  children?: ReactNode;
  title?: ReactNode;
  className?: string;
  classes?: typeof styles;
};

export const FooterLink = forwardRef<HTMLAnchorElement, FooterLinkProps>(
  ({ href, icon, children, className, ...props }, ref) => {
    return (
      <a
        ref={ref}
        href={href}
        className={cx(styles.link, className)}
        {...props}
      >
        {children}
        {icon}
      </a>
    );
  }
);

export const FooterLinksBlock = forwardRef<
  HTMLDivElement,
  FooterLinksBlockProps
>(({ title, links = [], children, className, classes = {}, ...props }, ref) => {
  const classNames = useClassnames(styles, classes);
  return (
    <div
      ref={ref}
      className={cx(classNames.mainLinksBlock, className)}
      {...props}
    >
      <div className={classNames.sectionTitle}>{title}</div>
      {children ||
        links.map(item => (
          <FooterLink key={item.href} className={classNames.link} {...item} />
        ))}
    </div>
  );
});

FooterLinksBlock.displayName = 'FooterLinksBlock';
FooterLink.displayName = 'FooterLink';
