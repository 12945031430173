import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const UILabel = ({ type, size, children }) => {
  return (
    <div
      className={cx("UILabel", {
        "-accent": type === "accent",
        "-transparent": type === "transparent",
        "-grey-light": type === "grey-light",
        "-small": size === "small",
        "-medium": size === "medium",
      })}
    >
      {children}
    </div>
  );
};

UILabel.propTypes = {
  type: PropTypes.oneOf(["accent", "transparent", "grey-light"]),
  size: PropTypes.oneOf(["medium", "small"]),
};

export default UILabel;

UILabel.defaultProps = {
  type: "grey-light",
  size: "medium",
};
