import React, { forwardRef, useState, MouseEvent, useMemo } from 'react';
import Chip from '../Chip';
import styles from './Pagination.module.scss';
import mergeClasses from '../../shared/mergeClasses';
import LeftIcon from '../../icons/left.svg';
import RightIcon from '../../icons/right.svg';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

export enum PagePlaceholderTypes {
  Ellipsis = 'ellipsis',
}

export type Page = number | PagePlaceholderTypes;

export type PaginationRef = HTMLDivElement;

export type PaginationProps = {
  defaultPage?: number;
  maxButtonCount?: number;
  count?: number;
  onChange?: (value: number, event: MouseEvent<HTMLElement>) => void;
  classes?: typeof styles;
};

const Pagination = forwardRef<PaginationRef, PaginationProps>(
  (
    {
      defaultPage = 1,
      maxButtonCount = 5,
      count = 2,
      classes = {},
      onChange = () => {},
    },
    ref
  ) => {
    const [page, setPage] = useState(defaultPage);
    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);
    const pages: Page[] = useMemo(() => {
      const middleButton = Math.round(maxButtonCount / 2);

      if (count <= maxButtonCount || page <= middleButton) {
        return range(1, Math.min(count, maxButtonCount));
      }

      const startPages = range(1, middleButton - 2);
      const endPagesButtonCount = maxButtonCount - middleButton;
      const endPages = range(
        Math.min(page, count - endPagesButtonCount),
        Math.min(page + endPagesButtonCount, count)
      );

      return [...startPages, PagePlaceholderTypes.Ellipsis, ...endPages];
    }, [page, count, maxButtonCount]);

    const handleClick = (event: MouseEvent<HTMLElement>, value: number) => {
      setPage(value);
      onChange(value, event);
    };

    return (
      <div className={classNames.root} ref={ref}>
        {count > maxButtonCount && (
          <Chip
            className={classNames.button}
            onClick={(event: MouseEvent<HTMLElement>) =>
              handleClick(event, page - 1)
            }
            disabled={page === 1}
            icon={<LeftIcon />}
          />
        )}
        {pages.map((p: Page) => {
          return p === PagePlaceholderTypes.Ellipsis ? (
            <Chip key={p} className={classNames.button} disabled={true}>
              ...
            </Chip>
          ) : (
            <Chip
              key={p}
              className={classNames.button}
              onClick={(event: MouseEvent<HTMLElement>) =>
                handleClick(event, p)
              }
              active={page === p}
            >
              {p}
            </Chip>
          );
        })}
        {count > maxButtonCount && (
          <Chip
            className={classNames.button}
            onClick={(event: MouseEvent<HTMLElement>) =>
              handleClick(event, page + 1)
            }
            disabled={page === count}
            icon={<RightIcon />}
          />
        )}
      </div>
    );
  }
);

Pagination.displayName = 'Pagination';

export default Pagination;
