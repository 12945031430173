import React from "react";

export default () => (
  <svg
    className="ico"
    width="20"
    height="20"
    fill="#505050"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.5912 3.225L13.1079 1.75L4.86621 10L13.1162 18.25L14.5912 16.775L7.81621 10L14.5912 3.225Z" />
  </svg>
);
