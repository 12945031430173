import React, { ReactNode, useContext, useMemo } from 'react';
import { SelectContext } from './context';

export type SelectSectionProps = {
  children: ReactNode;
};

const SelectSection = ({ children }: SelectSectionProps) => {
  const { selected, onItemClick, onSelect } = useContext(SelectContext);

  const childrenArray = React.Children.toArray(children);

  const sectionValues = childrenArray
    .filter(
      child =>
        React.isValidElement(child) &&
        child.props.value &&
        !child.props.disabled
    )
    .map(child => (child as React.ReactElement).props.value);

  const handleSelectAll = () => {
    const selectedWithoutSectionValues = selected.filter(
      (e: any) => !sectionValues.includes(e)
    );
    if (isSelectedAll) {
      onSelect(selectedWithoutSectionValues);
    } else {
      onSelect([...selectedWithoutSectionValues, ...sectionValues]);
    }
  };

  const isSelectedAll = useMemo(() => {
    if (!Array.isArray(selected)) return false;
    return sectionValues.every((e: any) => selected.includes(e));
  }, [selected, sectionValues]);

  const items = childrenArray.map(child => {
    if (!React.isValidElement(child)) return null;

    if (child.props.children) {
      if (React.isValidElement(child.props.children)) {
        return React.cloneElement(child as React.ReactElement, {
          children: React.cloneElement(child.props.children, {
            onClick: handleSelectAll,
            selected: isSelectedAll,
          }),
        });
      } else if (child.props.value) {
        return React.cloneElement(child as React.ReactElement, {
          onClick: () => onItemClick(child),
          selected: Array.isArray(selected)
            ? selected.includes(child.props.value)
            : selected === child.props.value,
        });
      } else {
        return child;
      }
    }
    return child;
  });

  return items;
};

export default SelectSection;
