import React, {
  forwardRef,
  ReactNode,
  ElementType,
  ReactElement,
  useImperativeHandle,
  useRef,
  useEffect,
  useContext,
} from 'react';
import cx from 'classnames';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@flatfy/supernova-new/types/types/polymorphic';
import { useClassnames } from '../../shared/useClassnames';
import { TabsContext, TabsContextType } from './TabsContext';
import styles from './Tabs.module.scss';

export type TabProps<C extends ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    onClick: (value: string, event?: MouseEvent) => void;
    value: string;
    children: ReactNode;
    className?: string;
    classes?: typeof styles;
    selected?: boolean;
  }
>;

type DEFAULT_COMPONENT = 'div';
const DEFAULT_COMPONENT = 'div';

export type TabComponent = <C extends ElementType = DEFAULT_COMPONENT>(
  props: TabProps<C>
) => ReactElement | null;

// eslint-disable-next-line react/display-name
const Tab: TabComponent = forwardRef(
  <C extends ElementType = DEFAULT_COMPONENT>(
    {
      component,
      onClick,
      value,
      children,
      className,
      classes = {},
      selected = false,
      ...props
    }: TabProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const classNames = useClassnames(styles, classes);
    const rootRef = useRef<HTMLDivElement>(null);
    const { updateTabStyle } = useContext(TabsContext) as TabsContextType;
    let Root = component || DEFAULT_COMPONENT;

    useImperativeHandle(ref, () => {
      return rootRef;
    });

    const handleClick = (event: MouseEvent) => {
      if (selected || !onClick) return;

      onClick(value, event);
    };

    useEffect(() => {
      if (!selected || !rootRef.current || !updateTabStyle) return;

      updateTabStyle({
        width: rootRef.current.offsetWidth,
        left: rootRef.current.offsetLeft,
      });
    }, [selected, rootRef]);

    return (
      <Root
        className={cx(
          classNames.tab,
          {
            [classNames.selected]: selected,
          },
          className
        )}
        ref={rootRef}
        onClick={handleClick}
        {...props}
      >
        <div className={classNames.content}>{children}</div>
      </Root>
    );
  }
);

export default Tab;
