import cx from 'classnames';
import React, {
  CSSProperties,
  ReactNode,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { useClickAway } from 'react-use';
import Button from 'components/refresh/Button';
import mergeClasses from 'shared/mergeClasses';
import { useChromeIos } from 'shared/isChromeIos';
import CloseIcon from '../../../icons/refresh/close.svg';
import ArrowBackIcon from '../../../icons/refresh/arrow-back.svg';
import { useLockBodyScroll } from 'helpers/useLockBodyScroll';
import { useTheme } from 'components/ThemeProvider';
import styles from './Modal.module.scss';

export type ModalFooterProps = {
  onClose: () => void;
};

export type ModalProps = {
  opened: boolean;
  title?: ReactNode;
  onClose?: () => void;
  onCloseDone?: () => void;
  children: ReactNode;
  fixedWidth?: boolean;
  width?: number;
  fixedHeight?: boolean;
  centered?: boolean;
  footer?: (value: ModalFooterProps) => ReactNode;
  withArrowBack?: boolean;
  classes?: typeof styles;
  lockBodyScroll?: boolean;
};

const Modal = ({
  opened,
  fixedWidth,
  fixedHeight,
  centered,
  children,
  title,
  onClose = () => {},
  onCloseDone = () => {},
  footer,
  width,
  withArrowBack,
  classes = {},
  lockBodyScroll = false,
}: ModalProps) => {
  const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);
  const ref = useRef<HTMLDivElement>(null);
  const isChromeIos = useChromeIos();
  const theme = useTheme();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useLockBodyScroll(lockBodyScroll, opened);

  const handleClose = () => {
    if (opened) {
      onClose();
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onCloseDone();
      }, theme.transition.popup * 1000);
    }
  };

  useClickAway(ref, handleClose);

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  return (
    <div
      className={cx(classNames.root, {
        [classNames.rootOpened]: opened,
        [classNames.fixedWidth]: fixedWidth,
        [classNames.fixedHeight]: fixedHeight,
        [classNames.centered]: centered,
        [classNames.noTransitions]: isChromeIos,
      })}
      style={
        width
          ? ({
              '--width': `${width}px`,
            } as CSSProperties)
          : {}
      }
      tabIndex={-1}
    >
      <div
        className={cx(classNames.content, {
          [classNames.contentOpened]: opened,
          [classNames.noTransitions]: isChromeIos,
        })}
        ref={ref}
        role="dialog"
        aria-modal
      >
        <div
          className={cx(classNames.header, {
            [classNames.withArrowBack]: withArrowBack,
          })}
        >
          {withArrowBack && (
            <div className={classNames.arrowBack}>
              <Button
                variant="secondary-icon"
                leftIcon={<ArrowBackIcon />}
                onClick={handleClose}
              />
            </div>
          )}
          <div className={classNames.title}>{title}</div>
          <div className={classNames.close}>
            <Button
              variant="secondary-icon"
              leftIcon={<CloseIcon />}
              onClick={handleClose}
            />
          </div>
        </div>
        <div className={classNames.contentInner}>{children}</div>
        {footer && (
          <div className={classNames.footer}>
            {footer({ onClose: handleClose })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
