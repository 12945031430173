import React, { ReactNode, forwardRef } from 'react';
import styles from './Footer.module.scss';
import { Button } from '@flatfy/supernova-new';
import { ButtonProps } from '@flatfy/supernova-new/types/components/Button';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';

export type FooterSocialLinkProps = Partial<ButtonProps>;

export const FooterSocialLink = forwardRef<
  HTMLAnchorElement,
  FooterSocialLinkProps
>(({ href, icon, className, classes = {}, ...props }, ref) => {
  const classNames = useClassnames(styles, classes);
  return (
    <Button
      variant="ghost-light"
      size="small"
      icon={icon}
      href={href}
      ref={ref}
      className={cx(classNames.socialLink, className)}
      classes={{ icon: classNames.socialLinkIcon }}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  );
});

FooterSocialLink.displayName = 'FooterSocialLink';
