import React, { forwardRef, useMemo, useState } from 'react';
import InputRange, {
  Range as RangeType,
  InputRangeClassNames,
  InputRangeProps,
} from 'react-input-range';
import cx from 'classnames';
import mergeClasses from '../../shared/mergeClasses';
import styles from './Range.module.scss';

export type RangeProps = Pick<
  InputRangeProps,
  'onChange' | 'onChangeStart' | 'onChangeComplete'
> & {
  rangeValues: RangeType;
  initialValues: RangeType | number;
  label: string;
  step?: number;
  classes?: typeof styles;
  disabled?: boolean;
  formatValue?: (value: RangeType | number) => string;
};

const Range = forwardRef<HTMLDivElement, RangeProps>(
  (
    {
      rangeValues,
      initialValues,
      label,
      step = 1,
      classes = {},
      disabled = false,
      onChange = () => {},
      formatValue,
      onChangeStart,
      onChangeComplete,
    },
    ref
  ) => {
    const [value, setValue] = useState<RangeType | number>(initialValues);

    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);

    return (
      <div
        className={cx(classNames.root, {
          [classNames.disabled]: disabled,
        })}
        ref={ref}
      >
        <div className={classNames.label}>{label}</div>
        <div className={classNames.value}>
          {formatValue
            ? formatValue(value)
            : typeof value === 'number'
            ? value
            : `${value.min} — ${value.max}`}
        </div>
        <InputRange
          minValue={rangeValues.min}
          maxValue={rangeValues.max}
          value={value}
          onChange={v => {
            setValue(v);
            onChange(v);
          }}
          onChangeStart={onChangeStart}
          onChangeComplete={onChangeComplete}
          step={step}
          disabled={disabled}
          classNames={
            {
              track: classNames.track,
              activeTrack: classNames.activeTrack,
              slider: cx(classNames.slider, {
                [classNames.sliderDisabled]: disabled,
              }),
              valueLabel: classNames.valueLabel,
              maxLabel: classNames.maxLabel,
              minLabel: classNames.minLabel,
            } as InputRangeClassNames
          }
        />
      </div>
    );
  }
);

Range.displayName = 'Range';

export default Range;
