import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  forwardRef,
} from 'react';
import styles from './NavigationSidebar.module.scss';
import cx from 'classnames';
import { Button } from '@flatfy/supernova-new';
import { ButtonProps } from '@flatfy/supernova-new/types/components/Button';
import { useClassnames } from 'shared/useClassnames';
import { CloseIcon, LogoIcon } from 'icons';
import { DataAttributes } from 'types/base';
import { AppLinksProps } from 'components/AppLinks';

export type NavigationSidebarSimpleLinkProps =
  Partial<HTMLHyperlinkElementUtils> &
    HTMLAttributes<HTMLElement> & { icon?: ReactNode };

export type NavigationSidebarProps = {
  classes?: typeof styles;
  className?: string;
  logo?: ReactNode;
  logoLink?: string;
  /**  <a href="#link-props">NavigationSidebarSimpleLinkProps</a> */
  links: NavigationSidebarSimpleLinkProps[] | ReactNode;
  /**  <a href="#link-props">NavigationSidebarSimpleLinkProps</a> */
  projects: NavigationSidebarSimpleLinkProps[] | ReactNode;
  /** <a href="https://supernova.lun.dev/button" target="_blank">ButtonProps</a> */
  socialLinks: ButtonProps[] | ReactNode;
  onClose?: () => void;
  opened?: boolean;
  socialLinksTitle?: ReactNode;
  projectsTitle?: ReactNode;
  projectsTitlePrefix?: ReactNode;
  /** <a href="https://supernova.lun.dev/button" target="_blank">ButtonProps</a> */
  CloseButtonOptions?: DataAttributes & Partial<ButtonProps>;
  AppLinksElement?: ReactElement<AppLinksProps>;
};

export const NavigationSidebarSocialLink = forwardRef<
  HTMLAnchorElement,
  Partial<ButtonProps>
>(({ className, href, classes = {}, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      href={href}
      target="_blank"
      size="small"
      variant="ghost-dark"
      {...props}
      classes={{
        ...classes,
        root: cx(
          styles.socialsButton,
          styles.ghostDarkButton,
          classes.root,
          className
        ),
        icon: cx(styles.ghostDarkButtonIcon, classes.icon),
      }}
    />
  );
});

export const NavigationSidebarLink = forwardRef<
  HTMLAnchorElement,
  NavigationSidebarSimpleLinkProps
>(({ href, children, icon, ...props }, ref) => {
  return (
    <a ref={ref} href={href} {...props}>
      <span>{children}</span>
      {icon}
    </a>
  );
});

export const NavigationSidebarProjectLink = forwardRef<
  HTMLAnchorElement,
  NavigationSidebarSimpleLinkProps
>(({ className, href, icon, children, ...props }, ref) => {
  return (
    <a
      ref={ref}
      href={href}
      className={cx(styles.project, className)}
      {...props}
    >
      {icon}
      <span>{children}</span>
    </a>
  );
});

export const NavigationSidebar = forwardRef<
  HTMLDivElement,
  NavigationSidebarProps
>(
  (
    {
      classes = {},
      className,
      links,
      socialLinks,
      socialLinksTitle,
      projects,
      projectsTitle,
      projectsTitlePrefix,
      opened,
      onClose = () => {},
      logo = <LogoIcon />,
      logoLink,
      CloseButtonOptions = {},
      AppLinksElement,
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <>
        <div
          className={cx(classNames.backdrop, { [classNames.hidden]: !opened })}
          onClick={onClose}
        />
        <div
          ref={ref}
          className={cx(classNames.root, className, {
            [classNames.opened]: opened,
          })}
        >
          <div className={classNames.header}>
            <a href={logoLink} className={classNames.logo}>
              {logo}
            </a>
            <Button
              size="small"
              variant="ghost-dark"
              className={classNames.ghostDarkButton}
              onClick={onClose}
              icon={<CloseIcon />}
              {...CloseButtonOptions}
            />
          </div>
          <hr />

          <div className={classNames.links}>
            {Array.isArray(links)
              ? links.map(link => (
                  <NavigationSidebarLink key={link.href} {...link} />
                ))
              : links}
          </div>
          <hr />
          <div className={classNames.socials}>
            <div className={classNames.socialsTitle}>{socialLinksTitle}</div>
            {Array.isArray(socialLinks)
              ? socialLinks.map(item => (
                  <NavigationSidebarSocialLink key={item.href} {...item} />
                ))
              : socialLinks}
          </div>
          <hr />
          {AppLinksElement && (
            <>
              <div className={classNames.apps}>
                {React.cloneElement(AppLinksElement, {
                  classes: {
                    root: classNames.appsRoot,
                    link: classNames.appsLink,
                  },
                })}
              </div>
              <hr />
            </>
          )}
          <div className={classNames.projects}>
            <div className={classNames.projectsTitle}>
              {projectsTitle}
              <div className={classNames.projectsLogo}>
                <span>{projectsTitlePrefix}</span>
                {logo}
              </div>
            </div>
            <div className={classNames.projectsList}>
              {Array.isArray(projects)
                ? projects.map(project => (
                    <NavigationSidebarProjectLink
                      key={project.href}
                      {...project}
                    />
                  ))
                : projects}
            </div>
          </div>
        </div>
      </>
    );
  }
);

NavigationSidebar.displayName = 'NavigationSidebar';
NavigationSidebarProjectLink.displayName = 'NavigationSidebarProjectLink';
NavigationSidebarLink.displayName = 'NavigationSidebarLink';
NavigationSidebarSocialLink.displayName = 'NavigationSidebarSocialLink';

export default NavigationSidebar;
