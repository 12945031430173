import React, {
  ElementType,
  forwardRef,
  HTMLAttributes,
  ReactComponentElement,
  ReactNode,
} from 'react';
import cx from 'classnames';
import styles from './Menu.module.scss';

export type MenuSubheaderProps = HTMLAttributes<HTMLElement> & {
  children?: ReactNode;
  className?: string;
  component?: ElementType;
  title?: string;
  classes?: Partial<{
    root: string;
    title: string;
    divider: string;
  }>;
};

const MenuSubheader = forwardRef<any, MenuSubheaderProps>(
  (
    {
      children,
      title,
      className,
      component: Root = 'div',
      classes = {},
      ...props
    },
    ref
  ) => {
    return (
      <Root
        className={cx(styles.subheader, classes.root, className)}
        ref={ref}
        {...props}
      >
        {children && (
          <div className={cx(styles.subheaderTitle, classes.title)}>
            {React.isValidElement(children) &&
            (children as ReactComponentElement<any>).type.displayName ===
              'MenuItem'
              ? React.cloneElement(children as React.ReactElement, {
                  className: styles.menuItem,
                })
              : children}
          </div>
        )}
        <div className={cx(styles.subheaderDivider, classes.divider)} />
      </Root>
    );
  }
);

MenuSubheader.displayName = 'MenuSubheader';

export default MenuSubheader;
