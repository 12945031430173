import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { IEvent } from "../../types/event";
import { attachGaDataAttributes } from "../../utils/analytics";
import Close from "../../icons/Close";
import Illustration from "../../icons/SectionFeedbackIllustration";
import UIButton from "../Button/Button";

const UISectionFeedback = ({
  event,
  title,
  onClick,
  classes,
  className,
  onClose,
  show,
  attributes,
}) => {
  const handleCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      {...attributes}
      className={cx("UISectionFeedback", className, classes.root, {
        "-show": show,
      })}
      onClick={onClick}
      {...attachGaDataAttributes(event)}
    >
      <div
        className={cx("UISectionFeedback-illustration", classes.illustration)}
      >
        <Illustration />
      </div>
      <div className={cx("UISectionFeedback-title", classes.title)}>
        {title}
      </div>
      <div className={cx("UISectionFeedback-close", classes.close)}>
        <UIButton
          appearance="ghost-dark"
          event={{ ...event, label: "close_click" }}
          size="small"
          icon={<Close />}
          onClick={handleCloseClick}
        />
      </div>
    </div>
  );
};

const ISectionFeedbackClasses = {
  root: PropTypes.string,
  illustration: PropTypes.string,
  title: PropTypes.string,
  close: PropTypes.string,
};

UISectionFeedback.propTypes = {
  event: PropTypes.shape(IEvent).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
    .isRequired,
  classes: PropTypes.shape(ISectionFeedbackClasses),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  attributes: PropTypes.object,
};

UISectionFeedback.defaultProps = {
  classes: {},
  onClose: () => {},
  onClick: () => {},
  attributes: {},
};

export default UISectionFeedback;
