import React, { forwardRef } from 'react';
import cx from 'classnames';
import { ToggleChipProps } from '@flatfy/supernova-new/types/components/Chip/ToggleChip';
import { Chip, ToggleChip } from '@flatfy/supernova-new';

import styles from './CurrencySwitcher.module.scss';

export type CurrencySwitcherItem = { label: string; value: string };

export type CurrencySwitcherProps = Omit<
  Partial<ToggleChipProps>,
  'onChange'
> & {
  /** Callback on switcher toggle */
  onChange?: (value: string) => void;
  /** Current value key */
  value?: string;
  /** <a href="#switcher-item">CurrencySwitcherItem definition</a> */
  values?: CurrencySwitcherItem[];
};

const DEFAULT_CURRENCY_LIST = [
  { value: 'ua', label: 'грн' },
  { value: 'usd', label: '$' },
];

const CurrencySwitcher = forwardRef<HTMLDivElement, CurrencySwitcherProps>(
  (
    { onChange = () => {}, value, values = DEFAULT_CURRENCY_LIST, ...props },
    ref
  ) => {
    return (
      <ToggleChip
        {...props}
        ref={ref}
        className={cx(styles.root, props.className)}
      >
        {values.map(item => (
          <Chip
            key={item.value}
            onClick={() => onChange(item.value)}
            active={value === item.value}
            classes={{
              root: styles.chip,
              active: styles.active,
            }}
          >
            {item.label}
          </Chip>
        ))}
      </ToggleChip>
    );
  }
);

CurrencySwitcher.displayName = 'CurrencySwitcher';

export default CurrencySwitcher;
