import React, { ReactNode, useRef, cloneElement, useState } from 'react';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';
import cx from 'classnames';
import styles from './Tooltip.module.scss';

export type TooltipProps = {
  children: JSX.Element;
  placement?: PopperJS.Placement;
  content?: ReactNode;
  className?: string;
};

const Tooltip = ({
  children,
  content,
  placement = 'auto',
  className,
}: TooltipProps) => {
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { styles: popperStyles, attributes } = usePopper(
    anchorRef,
    tooltipRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  );

  const trigger = cloneElement(children, {
    className: styles.trigger,
    ref: setAnchorRef,
  });

  return (
    <>
      {trigger}
      <div
        className={cx(styles.content, className)}
        style={popperStyles.popper}
        ref={tooltipRef}
        {...attributes.popper}
      >
        {content}
      </div>
    </>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
