import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const UIToggleChip = ({ appearance, className, children, attributes }) => (
  <div
    className={cx(
      "UIToggleChip",
      {
        "-regular": appearance === "regular",
        "-overlay": appearance === "overlay",
      },
      className
    )}
    {...attributes}
  >
    {children}
  </div>
);

UIToggleChip.propTypes = {
  appearance: PropTypes.oneOf(["regular", "overlay"]),
  className: PropTypes.string,
  attributes: PropTypes.object,
};

UIToggleChip.defaultProps = {
  appearance: "regular",
  attributes: {},
};

export default UIToggleChip;
