import React from "react";

export default () => (
  <svg
    className="ico"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.0832 2.5l.8333.83333h2.9167V5H4.1665V3.33333h2.91667L7.9165 2.5h4.1667zM4.99984 15.8333c0 .9167.75 1.6667 1.66666 1.6667h6.6667c.9166 0 1.6666-.75 1.6666-1.6667V5.83333H4.99984v9.99997zm2.05-5.9333l1.175-1.175 1.775 1.7667L11.7665 8.725l1.175 1.175-1.7667 1.7667 1.7667 1.7666-1.175 1.175-1.76666-1.7666-1.76666 1.7666-1.175-1.175 1.76666-1.7666L7.04984 9.9z" />
  </svg>
);
