import React, {
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
  ReactNode,
  useContext,
} from 'react';
import cx from 'classnames';
import { useClassnames } from '../../shared/useClassnames';
import TabsContextProvider, {
  TabsContext,
  TabsContextType,
} from './TabsContext';
import styles from './Tabs.module.scss';

export type TabsProps = HTMLAttributes<HTMLDivElement> & {
  value?: string;
  children: ReactNode;
  className?: string;
  classes?: typeof styles;
  centered?: boolean;
  wide?: boolean;
  forDarkBackground?: boolean;
};

const TabsComponent = ({
  value,
  children,
  className,
  classes = {},
  centered = false,
  wide = false,
  forDarkBackground = false,
  ...props
}: TabsProps) => {
  const [mounted, setMounted] = useState(false);
  const { activeTabStyle } = useContext(TabsContext) as TabsContextType;
  const tabsWrapperRef = useRef<HTMLDivElement>(null);
  const classNames = useClassnames(styles, classes);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div
      className={cx(
        classNames.root,
        {
          [classNames.centered]: centered,
          [classNames.wide]: wide,
          [classNames.forDarkBackground]: forDarkBackground,
        },
        className
      )}
      {...props}
    >
      <div className={classNames.wrapper} ref={tabsWrapperRef}>
        {children}
        {mounted && value && (
          <span className={classNames.indicator} style={activeTabStyle} />
        )}
      </div>
    </div>
  );
};

const Tabs = (props: TabsProps) => {
  return (
    <TabsContextProvider>
      <TabsComponent {...props} />
    </TabsContextProvider>
  );
};

export default Tabs;
