import React, { forwardRef, ReactNode } from 'react';
import TextField, {
  MultilineProps,
  TextFieldProps,
  TextFieldRef,
} from 'components/TextField';
import cx from 'classnames';
import styles from './TextFieldRefresh.module.scss';
import { useClassnames } from 'shared/useClassnames';

export type TextFieldRefreshProps = Omit<TextFieldProps, 'variant' | 'size'> & {
  variant?: 'fill' | 'outline';
  size?: 'big' | 'small';
};

const TextFieldRefresh = forwardRef<
  TextFieldRef,
  TextFieldRefreshProps & MultilineProps
>(({ variant = 'fill', size = 'big', classes = {}, ...props }, ref) => {
  const classNames = useClassnames(styles, classes);
  return (
    <TextField
      ref={ref}
      classes={{
        root: cx(classNames.root, {
          [classNames.fill]: variant === 'fill',
          [classNames.outline]: variant === 'outline',
          [classNames.big]: size === 'big',
          [classNames.small]: size === 'small',
        }),
        label: classNames.label,
        input: classNames.input,
        icon: classNames.icon,
        endIcon: classNames.endIcon,
        startIcon: classNames.startIcon,
        error: classNames.error,
        helperText: classNames.helperText,
        active: classNames.active,
        filled: classNames.filled,
        disabled: classNames.disabled,
        additional: classNames.additional,
      }}
      {...props}
    />
  );
});

TextFieldRefresh.displayName = 'TextFieldRefresh';

export default TextFieldRefresh;
