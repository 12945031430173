import React, {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import maxSize from 'popper-max-size-modifier';
import * as PopperJS from '@popperjs/core';
import cx from 'classnames';
import { Modifier, usePopper } from 'react-popper';
import { useClickAway } from 'react-use';
import { CSSTransition } from 'react-transition-group';
import Backdrop from 'components/Backdrop';
import styles from './Popup.module.scss';
import { ModifierArguments, Options } from '@popperjs/core';
import { useClassnames } from 'shared/useClassnames';
import { useTheme } from '../ThemeProvider';

const MAX_HEIGHT_OFFSET = 16;

export type PopupProps = HTMLAttributes<HTMLDivElement> & {
  anchorEl?: HTMLElement | null;
  opened: boolean;
  placement?: PopperJS.Placement;
  onClose?: () => void;
  children?: ReactNode;
  classes?: typeof styles;
  className?: string;
  fullWidth?: boolean;
  maxHeight?: number;
  limitHeight?: boolean;
  wrapperEl?: HTMLElement | null;
  unmountOnClose?: boolean;
  fullScreenMobile?: boolean;
};

export type PopupRef = HTMLDivElement;

type PopupContentProps = {
  children: ReactNode;
  className?: string;
};

export const PopupContent = forwardRef<PopupRef, PopupContentProps>(
  ({ children, className }, ref) => (
    <div className={cx(styles.content, className)} ref={ref}>
      {children}
    </div>
  )
);

export const PopupActions = forwardRef<PopupRef, PopupContentProps>(
  ({ children, className }, ref) => (
    <div className={cx(styles.actions, className)} ref={ref}>
      {children}
    </div>
  )
);

const Popup = forwardRef<PopupRef, PopupProps>(
  (
    {
      opened,
      placement = 'bottom-start',
      fullWidth = false,
      limitHeight = false,
      unmountOnClose = false,
      classes = {},
      onClose = () => {},
      anchorEl,
      children,
      className,
      maxHeight,
      wrapperEl,
      fullScreenMobile = true,
    },
    ref
  ) => {
    const unmountTimeout = useRef<ReturnType<typeof setTimeout>>();
    const rootRef = useRef<HTMLDivElement>(null);
    const childrenRef = useRef<HTMLDivElement>(null);
    const classNames = useClassnames(styles, classes);
    const theme = useTheme();

    useImperativeHandle(ref, () => {
      return rootRef.current as HTMLDivElement;
    });

    useClickAway(rootRef, e => {
      if (!opened || anchorEl?.contains(e.target as Node)) return;
      onClose();
    });

    useEffect(() => () => {
      unmountTimeout.current = undefined;
      clearTimeout(unmountTimeout.current);
    });

    //@ts-ignore
    const applyMaxSize: Array<Modifier<'maxSize', Options>> = useMemo(() => {
      if (!limitHeight || !!wrapperEl) return [];
      return [
        maxSize,
        {
          name: 'applyMaxSize',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['maxSize'],
          fn({ state }: ModifierArguments<Options>) {
            const { height } = state.modifiersData.maxSize;
            state.styles.popper.maxHeight = `${height - MAX_HEIGHT_OFFSET}px`;
          },
        },
      ];
    }, [limitHeight, wrapperEl]);

    const {
      styles: popperStyles,
      attributes,
      state,
    } = usePopper(anchorEl, rootRef.current, {
      placement,
      modifiers: [
        {
          name: 'flip',
          options: {
            allowedAutoPlacements: ['top', 'bottom'],
          },
          enabled: !(limitHeight && wrapperEl),
        },
        ...applyMaxSize,
      ],
    });

    const maxHeightValue = useMemo(() => {
      if (maxHeight) return `${maxHeight}px`;
      if (!wrapperEl || !limitHeight || !anchorEl)
        return popperStyles.popper.maxHeight;
      const anchorRect = anchorEl.getBoundingClientRect();
      const wrapperRect = wrapperEl.getBoundingClientRect();
      const distanceToTop = anchorRect.top - wrapperRect.top;
      const distanceToBottom =
        wrapperRect.height - (distanceToTop + anchorRect.height);
      const result =
        (state?.placement.includes('top') ? distanceToTop : distanceToBottom) -
        MAX_HEIGHT_OFFSET;
      return `${result}px`;
    }, [
      maxHeight,
      wrapperEl,
      limitHeight,
      anchorEl,
      popperStyles.popper.maxHeight,
      state?.placement,
    ]);

    return (
      <>
        <Backdrop
          opened={opened}
          className={cx(classNames.backdrop, {
            [classNames.backdropOpened]: fullScreenMobile,
          })}
        />
        <div
          className={cx(
            classNames.root,
            {
              [classNames.opened]: opened,
              [classNames.top]: opened && state?.placement.includes('top'),
              [classNames.bottom]:
                opened && state?.placement.includes('bottom'),
              [classNames.fullWidth]: fullWidth,
              [classNames.fullScreenMobile]: fullScreenMobile,
            },
            className
          )}
          ref={rootRef}
          style={
            {
              '--transform': popperStyles.popper.transform,
              '--inset': popperStyles.popper.inset,
              '--right': popperStyles.popper.right,
              '--top': popperStyles.popper.top,
              '--bottom': popperStyles.popper.bottom,
              '--left': popperStyles.popper.left,
              '--max-height': maxHeightValue,
            } as CSSProperties
          }
          {...attributes.popper}
        >
          <CSSTransition
            nodeRef={childrenRef}
            in={opened}
            timeout={{
              exit: theme.transition.popup * 2 * 1000,
            }}
            unmountOnExit={unmountOnClose}
            classNames={{
              exit: classNames.exit,
              enterDone: classNames.enterDone,
            }}
          >
            <div ref={childrenRef} className={classNames.transitioned}>
              {children}
            </div>
          </CSSTransition>
        </div>
      </>
    );
  }
);

PopupContent.displayName = 'PopupContent';
PopupActions.displayName = 'PopupActions';
Popup.displayName = 'Popup';

export default Popup;
