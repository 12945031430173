import { useMemo } from 'react';
import { Classes } from 'types/base';
import mergeClasses from './mergeClasses';

export const useClassnames = function <T extends Classes>(
  baseClasses: T,
  additionalClasses: Partial<T>
): T {
  return useMemo(
    () => mergeClasses(baseClasses, additionalClasses),
    [baseClasses, additionalClasses]
  );
};
