import React, { CSSProperties, forwardRef } from 'react';
import variables from '@flatfy/supernova-new/styles/variables.module.scss';
import cx from 'classnames';
import styles from './Card.module.scss';
import { CardDeveloperDetails } from 'types/card';
import { useClassnames } from 'shared/useClassnames';

export type CardDeveloperProps = {
  data: CardDeveloperDetails;
  className?: string;
  classes?: typeof styles;
};

const CardDeveloper = forwardRef<HTMLDivElement, CardDeveloperProps>(
  ({ data, classes = {}, className }, ref) => {
    const classNames = useClassnames(styles, classes);

    if (!data) return null;
    return (
      <div
        className={cx(classNames.developerItem, className)}
        ref={ref}
        style={
          {
            '--card-color': data.cardColor,
            '--text-color': data.textColor || variables.white00,
          } as CSSProperties
        }
      >
        <div className={classNames.developerLogo}>
          <img src={data.logo} alt={data.name} />
        </div>
        <span className={classNames.text}>{data.name}</span>
      </div>
    );
  }
);

CardDeveloper.displayName = 'CardDeveloper';

export default CardDeveloper;
