import React from 'react';
import { CardLocationDetails } from 'types/card';
import * as Icons from 'icons';
import cx from 'classnames';
import styles from './Card.module.scss';
import { Typography } from '@flatfy/supernova-new';
import { useClassnames } from 'shared/useClassnames';

export type CardLocationProps = {
  data?: CardLocationDetails;
  isCottage: boolean;
  className?: string;
  classes?: typeof styles;
};

const CardLocation = ({
  data,
  isCottage,
  classes = {},
  className,
}: CardLocationProps) => {
  const classNames = useClassnames(styles, classes);

  if (!data) return null;

  if (data.placeholder) {
    return (
      <div className={cx(classNames.location, className)}>
        {data.placeholder}
      </div>
    );
  }

  return (
    <div
      className={cx(
        classNames.location,
        { [classNames.locationPlace]: !data.line },
        className
      )}
    >
      <div className={cx(classNames.locationLabel)}>
        {data.line === 'red' && <Icons.SubwayFilledRedIcon />}
        {data.line === 'green' && <Icons.SubwayFilledGreenIcon />}
        {data.line === 'blue' && <Icons.SubwayFilledBlueIcon />}
        {!data.line && <Icons.PlaceIcon />}
        <Typography
          variant="caption"
          className={classNames.locationCaption}
          component="span"
        >
          {data.name}
        </Typography>
      </div>
    </div>
  );
};

export default CardLocation;
