import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className="ico"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.67484 17.5L19.1665 10 1.67484 2.5 1.6665 8.33333 14.1665 10l-12.5 1.6667.00834 5.8333z" />
  </svg>
);
