import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import styles from './Label.module.scss';

export type LabelProps = HTMLAttributes<HTMLElement> & {
  variant?: 'grey-light' | 'accent' | 'transparent';
  size?: 'medium' | 'small';
  children: ReactNode;
  className?: string;
};
export type LabelRef = HTMLDivElement;

const Label = forwardRef<LabelRef, LabelProps>(
  (
    { variant = 'grey-light', size = 'medium', className, children, ...props },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={cx(
          styles.root,
          {
            [styles.greyLight]: variant === 'grey-light',
            [styles.accent]: variant === 'accent',
            [styles.transparent]: variant === 'transparent',
            [styles.medium]: size === 'medium',
            [styles.small]: size === 'small',
          },
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Label.displayName = 'Label';

export default Label;
