import React, { forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react';
import cx from 'classnames';
import styles from './Chip.module.scss';
import ChipClasses from './chip-classes';
import mergeClasses from 'shared/mergeClasses';

export type ChipProps = HTMLAttributes<HTMLElement> & {
  href?: string;
  variant?: 'regular' | 'overlay' | 'toggle';
  iconPosition?: 'left' | 'right';
  className?: string;
  active?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  classes?: typeof ChipClasses;
  icon?: ReactNode;
};

const Chip = forwardRef<any, ChipProps>(
  (
    {
      href,
      className,
      classes = {},
      variant = 'regular',
      iconPosition = 'left',
      active = false,
      disabled = false,
      children,
      icon,
      ...props
    },
    ref
  ) => {
    const Root = href ? 'a' : 'button';

    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);

    return (
      <Root
        ref={ref}
        className={cx(classNames.root, className, {
          [classNames.regular]: variant === 'regular',
          [classNames.overlay]: variant === 'overlay',
          [classNames.active]: active,
          [classNames.disabled]: disabled,
          [classNames.iconRight]: icon && children && iconPosition === 'right',
          [classNames.iconLeft]: icon && children && iconPosition === 'left',
        })}
        href={href}
        disabled={disabled}
        {...props}
      >
        {icon && <div className={classNames.icon}>{icon}</div>}
        {children && <span className={classNames.content}>{children}</span>}
      </Root>
    );
  }
);

Chip.displayName = 'Chip';

export default Chip;
