import React, { useEffect, forwardRef, useRef, useState } from 'react';
import styles from './Countdown.module.scss';
import cx from 'classnames';
import { HTMLAttributes } from 'react';
import { useClassnames } from 'shared/useClassnames';
import { Typography } from '@flatfy/supernova-new';

enum Unit {
  D = 'd',
  H = 'h',
  M = 'm',
  S = 's',
}

const pad = (n: number) => (n < 10 ? '0' : '') + n;

export type CountdownProps = HTMLAttributes<HTMLDivElement> & {
  dateTo: string;
  className?: string;
  classes?: typeof styles;
  unitsTranslation: {
    [key in Unit]: string;
  };
  withSeconds?: boolean;
};

const Countdown = forwardRef<HTMLDivElement, CountdownProps>(
  (
    {
      dateTo,
      className,
      classes = {},
      unitsTranslation,
      withSeconds = false,
      ...props
    },
    ref
  ) => {
    const intervalRef = useRef<ReturnType<typeof setInterval>>();
    const classNames = useClassnames(styles, classes);
    const targetDate = Date.parse(dateTo);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const data = [
      { id: Unit.D, title: unitsTranslation.d, value: days },
      { id: Unit.H, title: unitsTranslation.h, value: hours },
      { id: Unit.M, title: unitsTranslation.m, value: minutes },
    ];
    if (withSeconds) {
      data.push({ id: Unit.S, title: unitsTranslation.s, value: seconds });
    }

    const tick = () => {
      const currentDate = new Date().getTime();
      let secondsLeft = (targetDate - currentDate) / 1000;
      if (secondsLeft < 0) {
        clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }

      const nextDays = pad(secondsLeft / 86400);
      secondsLeft %= 86400;
      const nextHours = pad(secondsLeft / 3600);
      secondsLeft %= 3600;
      const nextMinutes = pad(secondsLeft / 60);
      const nextSeconds = pad(secondsLeft % 60);

      setDays(parseInt(nextDays, 10));
      setHours(parseInt(nextHours, 10));
      setMinutes(parseInt(nextMinutes, 10));
      setSeconds(parseInt(nextSeconds, 10));
    };

    useEffect(() => {
      tick();
      intervalRef.current = setInterval(tick, 1000);
    }, []);

    return (
      <div ref={ref} className={cx(classNames.root, className)} {...props}>
        {data.map(unit => (
          <div className={classNames.item} key={unit.id}>
            <Typography variant="h4" className={classNames.value}>
              {unit.value}
            </Typography>
            <Typography variant="caption" className={classNames.caption}>
              {unit.title}
            </Typography>
          </div>
        ))}
      </div>
    );
  }
);

Countdown.displayName = 'Countdown';

export default Countdown;
