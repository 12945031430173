import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  TextareaHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import cx from 'classnames';
import styles from './TextField.module.scss';
import { useClassnames } from 'shared/useClassnames';

export type InputRef = HTMLInputElement | HTMLTextAreaElement;

export type InputAttributes = InputHTMLAttributes<HTMLInputElement>;
export type TextareaAttributes = TextareaHTMLAttributes<HTMLTextAreaElement>;

export type MultilineProps =
  | {
      multiline?: false;
      InputProps?: Partial<InputAttributes>;
    }
  | {
      multiline: true;
      TextareaProps?: Partial<TextareaAttributes>;
    };

export type TextFieldProps = Omit<HTMLAttributes<HTMLElement>, 'onChange'> & {
  name?: string;
  label?: string | ReactNode;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: InputAttributes['onBlur'];
  onFocus?: InputAttributes['onFocus'];
  className?: string;
  required?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  classes?: Partial<typeof styles>;
  invalid?: boolean;
  onChange?: (value: string) => void;
  inputRef?: Ref<InputRef>;
  helperText?: string;
  RootProps?: HTMLAttributes<HTMLElement>;
  additionalText?: string | ReactNode;
};

export type TextFieldRef = HTMLDivElement;

export type TextFieldFocusEvent = FocusEvent<HTMLInputElement, Element> &
  FocusEvent<HTMLTextAreaElement, Element>;

const TextField = forwardRef<TextFieldRef, TextFieldProps & MultilineProps>(
  (
    {
      label,
      className,
      onChange = () => {},
      startIcon,
      endIcon,
      classes = {},
      inputRef,
      value,
      invalid = false,
      name,
      placeholder,
      required = false,
      onBlur,
      onFocus,
      helperText,
      RootProps = {},
      additionalText,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    const [focused, setFocused] = useState(false);

    const handleFocus = useCallback(
      (event: TextFieldFocusEvent) => {
        setFocused(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus]
    );

    const handleFocusOut = useCallback(
      (event: TextFieldFocusEvent) => {
        setFocused(false);
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur]
    );

    let baseInputAttributes = {
      className: classNames.input,
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        onChange(e.target.value),
      onFocus: handleFocus,
      onBlur: handleFocusOut,
      value,
      name,
      required,
      placeholder,
    };

    return (
      <>
        <div
          className={cx(
            classNames.root,
            {
              [classNames.active]: focused,
              [classNames.filled]: value && value !== '',
              [classNames.error]: invalid,
              [classNames.disabled]: props.disabled,
              [classNames.textarea]: props.multiline,
            },
            className
          )}
          ref={ref}
          {...RootProps}
        >
          {startIcon && (
            <div className={cx(classNames.icon, classNames.startIcon)}>
              {startIcon}
            </div>
          )}
          {endIcon && (
            <div className={cx(classNames.icon, classNames.endIcon)}>
              {endIcon}
            </div>
          )}
          {additionalText && (
            <div className={classNames.additional}>{additionalText}</div>
          )}
          {props.multiline === true ? (
            <textarea
              {...props.TextareaProps}
              {...baseInputAttributes}
              ref={inputRef as Ref<HTMLTextAreaElement>}
            />
          ) : (
            <input
              {...props.InputProps}
              {...baseInputAttributes}
              ref={inputRef as Ref<HTMLInputElement>}
            />
          )}
          {label && (
            <label htmlFor={name} className={classNames.label}>
              {label}
              {required && <span>*</span>}
            </label>
          )}
        </div>
        {helperText && (
          <div className={classNames.helperText}>{helperText}</div>
        )}
      </>
    );
  }
);

TextField.displayName = 'TextField';

export default TextField;
