import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import styles from './Chip.module.scss';

export type ToggleChipProps = HTMLAttributes<HTMLElement> & {
  variant?: 'regular' | 'overlay';
  className?: string;
  children?: ReactNode;
};

const ToggleChip = forwardRef<HTMLDivElement, ToggleChipProps>(
  ({ variant = 'regular', children, className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        styles.toggleChip,
        {
          [styles.toggleChipRegular]: variant === 'regular',
          [styles.toggleChipOverlay]: variant === 'overlay',
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

ToggleChip.displayName = 'ToggleChip';

export default ToggleChip;
