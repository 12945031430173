import React, { useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { usePopper } from "react-popper";
import cx from "classnames";
import UIButton from "../Button/Button";

const MAX_HEIGHT_OFFSET = 16;

const UIPopup = ({
  opened,
  wide,
  height,
  anchorEl,
  root,
  onClose,
  limitHeight,
  className,
  actionButton,
  children,
  withSubpopup /* Свойство, которое предотвращает баг с анимацей на моб устройствах, если в попапе находится еще один попап */,
  classes = {},
  placementX = "left",
  placementY = "bottom",
}) => {
  const rootRef = useRef();
  const { state } = usePopper(anchorEl, rootRef.current, {
    placement: placementY,
    modifiers: [
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["top", "bottom"],
        },
      },
    ],
  });

  const handleOutsideClick = useCallback(
    (event) => {
      const isAnchorElClicked = anchorEl?.contains(event.target);
      const isRootClicked = rootRef.current?.contains(event.target);
      if (!opened || isAnchorElClicked || isRootClicked) {
        return;
      }
      onClose();
    },
    [anchorEl, onClose, opened]
  );

  const maxHeight = useMemo(() => {
    if (!anchorEl || !limitHeight) {
      return "0px";
    }
    const anchorRect = anchorEl.getBoundingClientRect();
    let distanceToTop;
    let rootHeight;
    if (root) {
      // relative to root element
      const rootRect = root.getBoundingClientRect();
      distanceToTop = anchorRect.top - rootRect.top;
      rootHeight = rootRect.height;
    } else {
      // relative to window
      distanceToTop = anchorRect.top;
      rootHeight = window.innerHeight;
    }
    const distanceToBottom = rootHeight - (distanceToTop + anchorRect.height);
    return `${
      (state?.placement === "top" ? distanceToTop : distanceToBottom) -
      MAX_HEIGHT_OFFSET
    }px`;
  }, [anchorEl, root, limitHeight, state]);

  useEffect(() => {
    if (opened) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [handleOutsideClick, opened]);

  return (
    <>
      <div
        className={cx("UIPopup", className, classes.root, {
          "-opened": opened,
          "-wide": wide,
          "-top": state && state.placement === "top",
          "-bottom": state && state.placement === "bottom",
          "-right": placementX === "right",
          "-with-subpopup": withSubpopup,
        })}
        style={{ "--height": height || (limitHeight && maxHeight) }}
        ref={rootRef}
      >
        <div className={cx("UIPopup-content", classes.content)}>{children}</div>
        {actionButton?.visible && (
          <UIButton fullWidth onClick={onClose} {...actionButton.attributes}>
            {actionButton.content}
          </UIButton>
        )}
      </div>
      <div
        className={cx("UIPopup-overlay", classes.overlay, {
          "-opened": opened,
        })}
      />
    </>
  );
};

UIPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  limitHeight: PropTypes.bool,
  wide: PropTypes.bool,
  height: PropTypes.string,
  anchorEl: PropTypes.object,
  root: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    overlay: PropTypes.string,
    content: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  withSubpopup: PropTypes.bool,
  placementX: PropTypes.oneOf(["right", "left"]),
  placementY: PropTypes.oneOf(["top", "bottom"]),
  actionButton: PropTypes.shape({
    visible: PropTypes.bool,
    content: PropTypes.string,
    attributes: UIButton.propTypes,
  }),
};

export default UIPopup;
