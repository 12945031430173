import React, {
  HTMLAttributes,
  ReactElement,
  ReactNode,
  forwardRef,
} from 'react';
import cx from 'classnames';
import CurrencySwitcher, {
  CurrencySwitcherItem,
  CurrencySwitcherProps,
} from 'components/CurrencySwitcher';
import { FooterProject, FooterProjectProps } from './FooterProject';
import { FooterLinksBlock, FooterLinksBlockProps } from './FooterLink';
import { useClassnames } from 'shared/useClassnames';
import { LanguageIcon, LogoLightIcon } from 'icons';
import { FooterSocialLink, FooterSocialLinkProps } from './FooterSocialLink';
import { DataAttributes } from 'types/base';
import { AppLinksProps } from 'components/AppLinks';
import styles from './Footer.module.scss';

type LinkAttributes = DataAttributes &
  Partial<HTMLHyperlinkElementUtils> &
  HTMLAttributes<HTMLElement>;

export type FooterProps = HTMLAttributes<HTMLDivElement> & {
  classes?: typeof styles;
  /** Current currency value */
  currency: string;
  /**  <a href='/currency-switcher#switcher-item' target='_blank'>CurrencySwitcherItem</a> */
  currencies?: CurrencySwitcherItem[];
  onCurrencyChange: (value: string) => void;
  /** Copy text */
  copy?: ReactNode;
  /** Current language text */
  language?: ReactNode;
  languageIcon?: ReactNode;
  /** Terms & Conditions text */
  terms?: ReactNode;
  languageLink?: string;
  onLanguageClick?: () => void;
  termsLink?: string;
  /** Logo Icon */
  logo?: ReactNode;
  logoLink?: string;
  socialLinksTitle?: string | ReactNode;
  /** extends <a href="supernova.lun.dev/button">ButtonProps</a>  */
  socialLinks?: FooterSocialLinkProps[] | ReactNode;
  projectsTitle?: ReactNode;
  projectsLogoIcon?: ReactNode;
  /** Project prefix text  */
  projectsPrefix?: ReactNode;
  /** <a href="#project">FooterProjectProps</a>  */
  projectsList?: FooterProjectProps[] | ReactNode;
  /** <a href="#block">FooterLinksBlock</a>  */
  linkBlocks?: FooterLinksBlockProps[] | ReactNode;
  LogoOptions?: LinkAttributes;
  TermsOptions?: LinkAttributes;
  LanguageOptions?: LinkAttributes;
  /** <a href="/currency-switcher">CurrencySwitcherProps</a>  */
  CurrencySwitcherOptions?: DataAttributes & CurrencySwitcherProps;
  AppLinksElement?: ReactElement<AppLinksProps>;
};

const Footer = forwardRef<HTMLDivElement, FooterProps>(
  (
    {
      className,
      classes = {},
      logo = <LogoLightIcon />,
      logoLink,
      socialLinks,
      socialLinksTitle,
      copy,
      terms,
      termsLink,
      linkBlocks,
      projectsList,
      projectsTitle,
      projectsPrefix,
      language,
      onLanguageClick,
      languageLink,
      languageIcon = <LanguageIcon />,
      projectsLogoIcon = <LogoLightIcon />,
      LanguageOptions = {},
      CurrencySwitcherOptions = {},
      TermsOptions = {},
      LogoOptions = {},
      currency,
      currencies,
      onCurrencyChange,
      AppLinksElement,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <footer ref={ref} className={cx(classNames.root, className)} {...props}>
        <div className={classNames.wrapper}>
          <div className={classNames.top}>
            <a href={logoLink} {...LogoOptions} className={classNames.logo}>
              {logo}
            </a>
            <div className={classNames.socialLinks}>
              <div className={classNames.sectionTitle}>{socialLinksTitle}</div>
              <div className={classNames.socialLinksContent}>
                {Array.isArray(socialLinks)
                  ? socialLinks.map(item => (
                      <FooterSocialLink
                        key={item.href}
                        classes={classes}
                        className={classNames.socialLink}
                        {...item}
                      />
                    ))
                  : socialLinks}
              </div>
            </div>
          </div>
          <hr className={classNames.line} />
          <div className={classNames.main}>
            <div className={classNames.mainLinks}>
              {Array.isArray(linkBlocks)
                ? linkBlocks.map(block => (
                    <FooterLinksBlock
                      key={block.id}
                      {...block}
                      classes={classes}
                    />
                  ))
                : linkBlocks}
              {AppLinksElement &&
                React.cloneElement(AppLinksElement, {
                  className: classNames.appLinks,
                })}
            </div>
            <div className={classNames.otherProjects}>
              <div className={classNames.otherProjectsHeader}>
                <div
                  className={cx(
                    classNames.sectionTitle,
                    classNames.otherProjectsHeaderTitle
                  )}
                >
                  {projectsTitle}
                </div>
                <div className={classNames.otherProjectsHeaderLogo}>
                  <div className={classNames.otherProjectsHeaderLogoPrefix}>
                    {projectsPrefix}
                  </div>
                  {projectsLogoIcon}
                </div>
              </div>
              <div className={classNames.otherProjectsList}>
                {Array.isArray(projectsList)
                  ? projectsList.map(item => (
                      <FooterProject
                        key={item.href}
                        {...item}
                        classes={classes}
                      />
                    ))
                  : projectsList}
              </div>
            </div>
          </div>
          <hr className={classNames.line} />
          <div className={classNames.bottom}>
            <div className={classNames.copyright}>
              <div className={classNames.copyrightText}>{copy}</div>
            </div>
            <div className={classNames.bottomAside}>
              <a href={termsLink} {...TermsOptions} className={classNames.link}>
                {terms}
              </a>
              <a
                href={languageLink}
                className={cx(
                  classNames.link,
                  classNames.iconLeft,
                  classNames.iconSmall
                )}
                onClick={onLanguageClick}
                {...LanguageOptions}
              >
                {languageIcon}
                {language}
              </a>
              <div className={classNames.currency}>
                <CurrencySwitcher
                  value={currency}
                  values={currencies}
                  onChange={onCurrencyChange}
                  variant="overlay"
                  {...CurrencySwitcherOptions}
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
);

Footer.displayName = 'Footer';

export default Footer;
