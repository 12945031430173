/**
 * В Chrome на iOS дизейблим анимации из-за бага, связаного с торможением анимаций в этом браузере
 * Смотрите:
 * - https://bugs.chromium.org/p/chromium/issues/detail?id=899130
 * - https://bugs.chromium.org/p/chromium/issues/detail?id=1249723
 * - https://bugs.chromium.org/p/chromium/issues/detail?id=1231712
 */

import { useEffect, useState } from 'react';

export const useChromeIos = () => {
  const [isChromeIos, setIsChromeIos] = useState(false);

  useEffect(() => {
    setIsChromeIos(
      typeof navigator !== 'undefined' &&
        navigator.userAgent.indexOf('CriOS') >= 0
    );
  }, []);

  return isChromeIos;
};
