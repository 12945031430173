import React, {
  createContext,
  CSSProperties,
  ReactNode,
  useContext,
} from 'react';
import useFontFamily, { FontFamilyValue } from 'shared/useFontFamily';
import useTransitionDuration, {
  TransitionDurationValue,
} from 'shared/useTransitionDuration';

export type ThemeProviderValue = {
  transition: TransitionDurationValue;
  fontFamily: FontFamilyValue;
};

export type ThemeProviderProps = {
  children: ReactNode;
  transitionDuration?: Partial<TransitionDurationValue>;
  fontFamily?: Partial<FontFamilyValue>;
};

export const useDefaultThemeValue = () => {
  const transitionDuration = useTransitionDuration();

  return {
    transition: transitionDuration.values,
  };
};

//@ts-ignore
export const ThemeContext = createContext<ThemeProviderValue>(null);

export const ThemeProvider = ({
  children,
  transitionDuration,
  fontFamily,
}: ThemeProviderProps) => {
  const normalizedTransitionDuration =
    useTransitionDuration(transitionDuration);
  const normalizedFontFamily = useFontFamily(fontFamily);

  return (
    <ThemeContext.Provider
      value={{
        transition: normalizedTransitionDuration.values,
        fontFamily: normalizedFontFamily.values,
      }}
    >
      <div
        style={
          {
            ...normalizedTransitionDuration.css,
            ...normalizedFontFamily.css,
          } as CSSProperties
        }
      >
        {children}
      </div>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const theme = useContext(ThemeContext);
  const defaultTheme = useDefaultThemeValue();

  return theme || defaultTheme;
};
