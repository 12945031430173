import React, {
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useMemo,
  createElement,
  CSSProperties,
} from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import mergeClasses from '../../shared/mergeClasses';
import TelegramIcon from '../../icons/contacts/telegram.svg';
import ViberIcon from '../../icons/contacts/viber.svg';
import EmailIcon from '../../icons/contacts/email.svg';
import styles from './ContactButton.module.scss';

const MAP_ICON_NAME_2_ICON: Record<string, string> = {
  telegram: TelegramIcon,
  viber: ViberIcon,
  email: EmailIcon,
};

type CommonContactButtonProps = HTMLAttributes<HTMLElement> & {
  className?: string;
  classes?: typeof styles;
  children?: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  icons?: ReactNode[] | string[];
  href?: string;
};

type OrdinaryButtonProps = CommonContactButtonProps & {
  variant?: 'primary' | 'secondary';
  color?: never;
};

type TransparentButtonProps = CommonContactButtonProps & {
  variant: 'transparent';
  color: `#${string}`;
};

export type ContactButtonProps = OrdinaryButtonProps | TransparentButtonProps;

const ContactButton = forwardRef<any, ContactButtonProps>(
  (
    {
      variant = 'secondary',
      classes = {},
      fullWidth = false,
      disabled = false,
      className,
      children,
      icons = [],
      href,
      color,
      ...props
    },
    ref
  ) => {
    const Root = href ? 'a' : 'button';

    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);
    const keys = useMemo(() => icons.map(() => uuid()), [icons]);

    return (
      <Root
        ref={ref}
        className={cx(
          classNames.root,
          {
            [classNames.primary]: variant === 'primary',
            [classNames.secondary]: variant === 'secondary',
            [classNames.transparent]: variant === 'transparent',
            [classNames.fullWidth]: fullWidth,
            [classNames.disabled]: disabled,
          },
          className
        )}
        disabled={disabled}
        href={href}
        style={color ? ({ '--color': color } as CSSProperties) : {}}
        {...props}
      >
        {children}
        <div className={classNames.icons}>
          {icons.map((icon, index) => (
            <div className={classNames.icon} key={keys[index]}>
              {typeof icon === 'string'
                ? createElement(MAP_ICON_NAME_2_ICON[icon])
                : icon}
            </div>
          ))}
        </div>
      </Root>
    );
  }
);

ContactButton.displayName = 'ContactButton';

export default ContactButton;
