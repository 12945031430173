import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import UIMenu from "../Menu/Menu";
import { IEvent } from "../../types/event";
import { attachGaDataAttributes } from "../../utils/analytics";
import UIPopup from "../Popup/Popup";

const UISelect = ({
  options = [],
  fieldOptions,
  menuOptions,
  onChange,
  closeOnChange,
  className,
  onOpen,
  onClose,
  children,
  classes = {},
  popupOptions = {},
}) => {
  const [opened, setOpened] = useState(false);
  const triggerRef = useRef();

  const handleClose = () => {
    if (onClose) onClose();
    setOpened(false);
  };

  const handleOpen = () => {
    if (onOpen) onOpen();
    setOpened(true);
  };

  const handleChange = (option) => {
    onChange(option);
    if (closeOnChange) {
      handleClose();
    }
  };

  return (
    <div className={cx("UISelect", className, classes.root)}>
      <div
        className={cx(
          "UISelect-field",
          {
            "-opened": opened,
            "-filled": fieldOptions.value && fieldOptions.value.length > 0,
            "-with-icon": fieldOptions.icon,
          },
          classes.field
        )}
        onClick={handleOpen}
        {...attachGaDataAttributes(fieldOptions.event)}
        ref={triggerRef}
      >
        <input
          name={fieldOptions.name}
          className={cx("UISelect-input", classes.input)}
          type="text"
          id={fieldOptions.name}
          value={fieldOptions.value}
          readOnly
        />
        <label
          htmlFor={fieldOptions.name}
          className={cx("UISelect-label", classes.label)}
        >
          {fieldOptions.label}
        </label>
        {fieldOptions.icon && (
          <div className={cx("UISelect-icon", classes.icon)}>
            {fieldOptions.icon}
          </div>
        )}
      </div>
      <UIPopup
        opened={opened}
        anchorEl={triggerRef.current}
        onClose={handleClose}
        classes={{
          root: classes.popup,
          content: classes.popupContent,
          overlay: classes.popupOverlay,
        }}
        {...popupOptions}
      >
        {children ? (
          children({ onChange: handleChange })
        ) : (
          <UIMenu options={options} onChange={handleChange} {...menuOptions} />
        )}
      </UIPopup>
    </div>
  );
};

const fieldPropTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

const cellPropTypes = {
  event: PropTypes.shape(IEvent).isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  component: PropTypes.func,
  attributes: PropTypes.object,
  nonInteractive: PropTypes.bool,
};

const optionsPropTypes = PropTypes.arrayOf(PropTypes.shape(cellPropTypes));

UISelect.propTypes = {
  fieldOptions: PropTypes.shape(fieldPropTypes).isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        disabled: PropTypes.bool,
        selected: PropTypes.bool,
        options: optionsPropTypes,
        event: PropTypes.shape(IEvent),
      })
    ) /* menu with sections */,
    optionsPropTypes /* menu without sections */,
  ]),
  popupOptions: PropTypes.shape({
    fullWidth: PropTypes.bool,
    height: PropTypes.string,
  }),
  menuOptions: PropTypes.shape({
    type: PropTypes.string,
    withCheckbox: PropTypes.bool,
  }),
  className: PropTypes.string,
  closeOnChange: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  classes: PropTypes.shape({
    root: PropTypes.string,
    field: PropTypes.string,
    input: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    popup: PropTypes.string,
    popupOverlay: PropTypes.string,
    popupContent: PropTypes.string,
  }),
};

export default UISelect;
