import React from 'react';
import cx from 'classnames';
import Button, { ButtonComponent } from '../Button';
import Icon from '../../../icons/refresh/right.svg';
import styles from './SplitButton.module.scss';

const SplitButton: ButtonComponent = props =>
  React.cloneElement(<Button {...props} />, {
    rightIcon: props.rightIcon || <Icon />,
    size: 'large',
    variant: 'primary',
    classes: {
      ...props.classes,
      root: cx(props.classes?.root, styles.root),
      content: cx(props.classes?.content, styles.content),
    },
  });

export default SplitButton;
