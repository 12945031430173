import React, { ElementType, ReactElement, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import styles from './BottomNavigationItem.module.scss';
import { useClassnames } from 'shared/useClassnames';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '@flatfy/supernova-new/types/types/polymorphic';

export type BottomNavigationItemProps<C extends ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      children?: ReactNode;
      className?: string;
      classes?: typeof styles;
      icon?: ReactNode;
      content?: ReactNode;
    }
  >;

type DEFAULT_COMPONENT = 'a';
const DEFAULT_COMPONENT = 'a';

export type BottomNavigationItemComponent = <
  C extends ElementType = DEFAULT_COMPONENT
>(
  props: BottomNavigationItemProps<C>
) => ReactElement | null;

// eslint-disable-next-line react/display-name
const BottomNavigationItem: BottomNavigationItemComponent = forwardRef(
  <C extends ElementType = DEFAULT_COMPONENT>(
    {
      component,
      className,
      classes = {},
      children,
      icon,
      content,
      ...props
    }: BottomNavigationItemProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Root = component || DEFAULT_COMPONENT;
    const classNames = useClassnames(styles, classes);
    return (
      <Root className={cx(classNames.root, className)} {...props} ref={ref}>
        {children ? (
          children
        ) : (
          <>
            <div className={classNames.icon}>{icon}</div>
            <div className={classNames.content}>{content}</div>
          </>
        )}
      </Root>
    );
  }
);

export default BottomNavigationItem;
