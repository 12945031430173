import React from "react";

export default () => (
  <svg
    className="ico"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8333 5.83317V8.32484C15.8333 8.32484 14.175 8.33317 14.1667 8.32484V5.83317H11.6667C11.6667 5.83317 11.675 4.17484 11.6667 4.1665H14.1667V1.6665H15.8333V4.1665H18.3333V5.83317H15.8333ZM13.3333 9.1665V6.6665H10.8333V4.1665H4.16667C3.25 4.1665 2.5 4.9165 2.5 5.83317V15.8332C2.5 16.7498 3.25 17.4998 4.16667 17.4998H14.1667C15.0833 17.4998 15.8333 16.7498 15.8333 15.8332V9.1665H13.3333ZM6.66667 12.4998L4.16667 15.8332H14.1667L10.8333 11.6665L8.33333 14.9998L6.66667 12.4998Z"
    />
  </svg>
);
