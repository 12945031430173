import { useMemo } from 'react';
import { useChromeIos } from './isChromeIos';

export type TransitionDurationValue = {
  standard: number,
  active: number,
  popup: number,
}

export type TransitionDuration = {
  values: TransitionDurationValue;
  css: {
    '--standardTransition': string;
    '--activeTransition': string;
    '--popupTransition': string;
  };
};

export const DefaultTransitionDuration: TransitionDurationValue = {
  standard: 0.25,
  active: 0.05,
  popup: 0.4,
};

const useTransitionDuration = (themeValue: Partial<TransitionDurationValue> = DefaultTransitionDuration) => {
  const composedTransitionDuration = useMemo(() => ({
    ...DefaultTransitionDuration,
    ...themeValue,
  }), []);
  const isChromeIos = useChromeIos();

  const transitionDurationValues = useMemo(() => {
    return Object.entries(composedTransitionDuration).reduce(
      (prev, [key, value]) => ({ ...prev, [key]: isChromeIos ? 0 : value }),
      composedTransitionDuration
    );
  }, [isChromeIos]);

  const transitionDurationCss = useMemo(() => {
    return Object.entries(transitionDurationValues).reduce(
      (prev, [key, value]) => ({ ...prev, [`--${key}Transition`]: `${value}s` }),
      {}
    );
  }, [transitionDurationValues])

  return {
    values: transitionDurationValues,
    css: transitionDurationCss,
  }
};

export default useTransitionDuration;
