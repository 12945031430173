import React, { forwardRef, ReactNode, useMemo } from 'react';
import cx from 'classnames';
import mergeClasses from 'shared/mergeClasses';
import Checkbox from 'components/refresh/Checkbox';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from 'types/polymorphic';
import styles from './MenuItemRefresh.module.scss';

export type MenuItemProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      className?: string;
      children: ReactNode;
      classes?: typeof styles;
      selected?: boolean;
      disabled?: boolean;
      nonInteractive?: boolean;
      value?: any;
      withCheckbox?: boolean;
      gutterLeft?: boolean;
      bold?: boolean;
    }
  >;

type DEFAULT_COMPONENT = 'div';
const DEFAULT_COMPONENT = 'div';

// Annotation for better component usage
export type MenuItemComponent = <
  C extends React.ElementType = DEFAULT_COMPONENT
>(
  props: MenuItemProps<C>
) => React.ReactElement | null;

// eslint-disable-next-line react/display-name
const MenuItem: MenuItemComponent = forwardRef(
  <C extends React.ElementType = DEFAULT_COMPONENT>(
    {
      component,
      className,
      children,
      classes = {},
      selected = false,
      disabled = false,
      nonInteractive = false,
      withCheckbox = false,
      gutterLeft = false,
      bold = false,
      ...props
    }: MenuItemProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Root = component || DEFAULT_COMPONENT;
    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);
    return (
      <Root
        className={cx(
          classNames.root,
          {
            [classNames.selected]: selected,
            [classNames.disabled]: disabled,
            [classNames.nonInteractive]: nonInteractive,
            [classNames.withCheckbox]: withCheckbox,
            [classNames.gutterLeft]: gutterLeft,
            [classNames.bold]: bold,
          },
          className
        )}
        {...props}
        ref={ref}
      >
        {withCheckbox ? (
          <Checkbox
            checked={selected}
            label={children?.toString()}
            className={classNames.checkbox}
            disabled={disabled}
          />
        ) : (
          <div className={classNames.content}>{children}</div>
        )}
      </Root>
    );
  }
);

export default MenuItem;
