import React, {
  forwardRef,
  HTMLAttributes,
  CSSProperties,
  ReactNode,
  useMemo,
} from 'react';
import cx from 'classnames';
import hexToRgb from '../../shared/hexToRgb';
import styles from './TwoLinerButton.module.scss';
import mergeClasses from '../../shared/mergeClasses';

const createBrandingColorCssVars = (
  brandingColor: `#${string}`
): CSSProperties => {
  const rgb = hexToRgb(brandingColor);

  return {
    '--branding-color-00': `rgb(${[...rgb].join(', ')})`,
    '--branding-color-01': `rgba(${[...rgb, 0.1].join(', ')})`,
    '--branding-color-02': `rgba(${[...rgb, 0.2].join(', ')})`,
  } as CSSProperties;
};

type CommonTwoLinerButtonProps = HTMLAttributes<HTMLElement> & {
  href?: string;
  caption: string;
  classes?: typeof styles;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  icon?: ReactNode;
  attributes?: {
    root: Record<string, string>;
    caption: Record<string, string>;
    content: Record<string, string>;
  };
  children: ReactNode;
};

type OrdinaryButtonProps = CommonTwoLinerButtonProps & {
  variant?: 'primary' | 'secondary' | 'transparent';
  brandingColor?: never;
};

type BrandedButtonProps = CommonTwoLinerButtonProps & {
  variant: 'branded';
  brandingColor: `#${string}`;
};

export type TwoLinerButtonProps = OrdinaryButtonProps | BrandedButtonProps;

const TwoLinerButton = forwardRef<any, TwoLinerButtonProps>(
  (
    {
      href,
      variant = 'secondary',
      caption,
      classes = {},
      children,
      disabled = false,
      fullWidth = false,
      brandingColor,
      className,
      icon,
      attributes = {
        root: {},
        caption: {},
        content: {},
      },
      ...props
    },
    ref
  ) => {
    const Root = href ? 'a' : 'button';

    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);

    return (
      <Root
        className={cx(
          classNames.root,
          {
            [classNames.primary]: variant === 'primary',
            [classNames.secondary]: variant === 'secondary',
            [classNames.transparent]: variant === 'transparent',
            [classNames.branded]: variant === 'branded',
            [classNames.fullWidth]: fullWidth,
          },
          className
        )}
        ref={ref}
        disabled={disabled}
        href={href}
        style={
          variant === 'branded' && brandingColor
            ? createBrandingColorCssVars(brandingColor)
            : undefined
        }
        {...props}
        {...attributes.root}
      >
        <div className={classNames.main}>
          <div className={classNames.column}>
            <div className={classNames.caption} {...attributes.caption}>
              {caption}
            </div>
            <div className={classNames.content} {...attributes.content}>
              {children}
            </div>
          </div>
          {icon && <div className={classNames.icon}>{icon}</div>}
        </div>
      </Root>
    );
  }
);

TwoLinerButton.displayName = 'TwoLinerButton';

export default TwoLinerButton;
