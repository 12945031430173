import React, {
  AnchorHTMLAttributes,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useMemo,
} from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';
import mergeClasses from '../../shared/mergeClasses';
import CircularProgress from '../CircularProgress';

export type ButtonBaseProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'transparent'
    | 'ghost-light'
    | 'ghost-dark'
    | 'overlay';
  size?: 'medium' | 'small';
  disabled?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  classes?: typeof styles;
  icon?: ReactNode;
  className?: string;
  children?: ReactNode;
};

export type ButtonProps =
  | ({ href?: never } & HTMLAttributes<HTMLElement> & ButtonBaseProps)
  | (Omit<Partial<AnchorHTMLAttributes<HTMLAnchorElement>>, 'href'> & {
      href: string;
    } & ButtonBaseProps);

const Button = forwardRef<any, ButtonProps>(
  (
    {
      variant = 'secondary',
      size = 'medium',
      disabled = false,
      fullWidth = false,
      isLoading = false,
      classes = {},
      href,
      icon,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const Root = href ? 'a' : 'button';
    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);
    const rootProps = href
      ? (props as AnchorHTMLAttributes<HTMLAnchorElement>)
      : (props as HTMLAttributes<HTMLElement>);

    return (
      <Root
        ref={ref}
        className={cx(
          classNames.root,
          {
            [classNames.primary]: variant === 'primary',
            [classNames.secondary]: variant === 'secondary',
            [classNames.transparent]: variant === 'transparent',
            [classNames.ghostDark]: variant === 'ghost-dark',
            [classNames.ghostLight]: variant === 'ghost-light',
            [classNames.overlay]: variant === 'overlay',
            [classNames.small]: size === 'small',
            [classNames.medium]: size === 'medium',
            [classNames.withIcon]: !!icon && !children,
            [classNames.fullWidth]: fullWidth,
            [classNames.isLoading]: isLoading,
            [classNames.disabled]: disabled,
          },
          className
        )}
        disabled={disabled}
        href={href}
        {...rootProps}
      >
        {icon && <div className={classNames.icon}>{icon}</div>}
        {children && <div className={classNames.content}>{children}</div>}
        {isLoading && (
          <div className={classNames.loader}>
            <CircularProgress classes={{ circle: classNames.loaderCircle }} />
          </div>
        )}
      </Root>
    );
  }
);

Button.displayName = 'Button';

export default Button;
