import Popup, { PopupContent, PopupProps } from 'components/Popup';
import React from 'react';
import MenuList from './MenuList';

export const Menu = ({ children, className, ...props }: PopupProps) => (
  <Popup {...props}>
    <PopupContent>
      <MenuList className={className}>{children}</MenuList>
    </PopupContent>
  </Popup>
);

export default Menu;
