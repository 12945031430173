import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';
import Chip from 'components/Chip';
import PlusIcon from '../../icons/plus.svg';
import MinusIcon from '../../icons/minus.svg';
import styles from './ZoomChip.module.scss';

export type ZoomChipProps = HTMLAttributes<HTMLElement> & {
  variant?: 'vertical' | 'horizontal';
  className?: string;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  classes?: typeof styles;
  zoomInIcon?: ReactNode;
  zoomOutIcon?: ReactNode;
};

const ZoomChip = forwardRef<HTMLDivElement, ZoomChipProps>(
  (
    {
      variant = 'horizontal',
      className,
      onZoomIn = () => {},
      onZoomOut = () => {},
      zoomInIcon = <PlusIcon />,
      zoomOutIcon = <MinusIcon />,
      classes = {},
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <div
        ref={ref}
        className={cx(
          classNames.root,
          { [classNames.vertical]: variant === 'vertical' },
          className
        )}
        {...props}
      >
        <Chip
          className={classNames.chip}
          icon={zoomInIcon}
          onClick={onZoomIn}
          variant="overlay"
        />
        <Chip
          className={classNames.chip}
          icon={zoomOutIcon}
          onClick={onZoomOut}
          variant="overlay"
        />
      </div>
    );
  }
);

ZoomChip.displayName = 'ZoomChip';

export default ZoomChip;
