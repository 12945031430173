import React, { ElementType, ReactNode } from 'react';
import Button, { ButtonProps } from '../Button';
import { PolymorphicRef } from 'types/polymorphic';
import TelegramIcon from './icon.svg';

export type DEFAULT_COMPONENT = 'button';
export const DEFAULT_COMPONENT = 'button';

export type TelegramButtonProps<C extends ElementType = DEFAULT_COMPONENT> =
  ButtonProps<C> & {
    icon?: ReactNode;
  };

export type TelegramButtonComponent = <
  C extends React.ElementType = DEFAULT_COMPONENT
>(
  props: TelegramButtonProps<C>
) => React.ReactElement | null;

// eslint-disable-next-line react/display-name
const TelegramButton: TelegramButtonComponent = React.forwardRef(
  <C extends React.ElementType = DEFAULT_COMPONENT>(
    { icon = <TelegramIcon />, component, ...props }: TelegramButtonProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    return (
      <Button
        ref={ref}
        leftIcon={icon}
        component={component}
        variant="colored"
        color="#34AADF"
        {...props}
      />
    );
  }
);

export default TelegramButton;
