import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { IEvent } from "../../types/event";
import { attachGaDataAttributes } from "../../utils/analytics";

const UIChip = React.forwardRef(
  (
    {
      appearance,
      type,
      icon,
      onClick,
      href,
      attributes,
      event,
      className,
      active,
      disabled,
      children,
    },
    ref
  ) => {
    const Root = href ? "a" : "button";
    const rootProps = {
      ...attributes,
      ...attachGaDataAttributes(event),
    };
    if (href) rootProps.href = href;

    return (
      <Root
        className={cx(
          "UIChip",
          {
            "-regular": appearance === "regular",
            "-overlay": appearance === "overlay",
            "-toggle": appearance === "toggle",
            "-icon": type === "icon",
            "-icon-right": type === "icon-right",
            "-icon-left": type === "icon-left",
            "-active": active,
            "-disabled": disabled,
          },
          className
        )}
        onClick={onClick}
        {...rootProps}
        ref={ref}
      >
        {icon && <div className="UIChip-icon">{icon}</div>}
        {children}
      </Root>
    );
  }
);

UIChip.propTypes = {
  appearance: PropTypes.oneOf(["regular", "overlay", "toggle"]),
  type: PropTypes.oneOf(["icon", "icon-right", "icon-left", "text"]),
  onClick: PropTypes.func,
  attributes: PropTypes.object,
  event: PropTypes.shape(IEvent).isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

UIChip.defaultProps = {
  appearance: "regular",
  type: "text",
  attributes: {},
};

export default UIChip;
