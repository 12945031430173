import React, { ReactNode, forwardRef, HTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './Chip.module.scss';

export type ChipsProps = HTMLAttributes<HTMLElement> & {
  wrap?: boolean;
  scrollable?: boolean;
  gutterBottom?: boolean;
  children?: ReactNode;
  className?: string;
};

export type ChipsRef = HTMLDivElement;

const Chips = forwardRef<ChipsRef, ChipsProps>(
  (
    {
      wrap = false,
      scrollable = false,
      gutterBottom = false,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const childrenArray = React.Children.toArray(children);
    const chips = childrenArray.map(child => {
      if (!React.isValidElement(child)) return child;
      return React.cloneElement(child as React.ReactElement<any>, {
        className: cx(child.props.className, styles.chipsChild),
      });
    });

    return (
      <div
        className={cx(
          styles.chips,
          {
            [styles.chipsWrap]: wrap,
            [styles.chipsScrollable]: scrollable,
            [styles.chipsGutterBottom]: gutterBottom,
          },
          className
        )}
        ref={ref}
        {...props}
      >
        {chips}
      </div>
    );
  }
);

Chips.displayName = 'Chips';

export default Chips;
