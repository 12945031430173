import Popup, {
  PopupContent,
  PopupProps,
  PopupContentProps,
} from 'components/refresh/Popup';
import React from 'react';
import MenuList from './MenuList';

export const Menu = ({
  children,
  className,
  headerTitle,
  actions,
  onClose,
  ...props
}: PopupProps & PopupContentProps) => (
  <Popup onClose={onClose} {...props}>
    <PopupContent onClose={onClose} headerTitle={headerTitle} actions={actions}>
      <MenuList className={className}>{children}</MenuList>
    </PopupContent>
  </Popup>
);

export default Menu;
