import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Left from "../../icons/Left";
import Right from "../../icons/Right";
import { attachGaDataAttributes } from "../../utils/analytics";
import { IEvent } from "../../types/event";

const UINavigationArrow = ({
  overlay,
  disabled,
  direction,
  attributes,
  event,
  onClick,
}) => {
  return (
    <button
      className={cn("UINavigationArrow", {
        "-overlay": overlay,
        "-disabled": disabled,
        "-left": direction === "left",
        "-right": direction === "right",
      })}
      {...attachGaDataAttributes(event)}
      {...attributes}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="UINavigationArrow-inner">
        {direction === "left" && <Left />}
        {direction === "right" && <Right />}
      </div>
    </button>
  );
};

UINavigationArrow.propTypes = {
  overlay: PropTypes.bool,
  disabled: PropTypes.bool,
  direction: PropTypes.oneOf(["left", "right"]).isRequired,
  attributes: PropTypes.object,
  event: PropTypes.shape(IEvent).isRequired,
  onClick: PropTypes.func,
};

export default UINavigationArrow;
