import * as React from "react";

function Telegram(props) {
  return (
    <svg viewBox="0 0 32 32" fill="none" className="ico" {...props}>
      <path
        d="M6.908 15.536c1.637-.908 3.464-1.665 5.17-2.426 2.938-1.246 5.887-2.471 8.865-3.612.58-.194 1.62-.384 1.723.48-.056 1.223-.286 2.44-.444 3.655-.4 2.674-.863 5.34-1.314 8.005-.156.887-1.26 1.347-1.968.779-1.7-1.155-3.412-2.3-5.09-3.481-.55-.562-.04-1.37.45-1.77 1.4-1.389 2.885-2.568 4.212-4.028.358-.87-.7-.137-1.049.088-1.916 1.329-3.786 2.739-5.806 3.907-1.032.571-2.235.083-3.267-.236-.925-.386-2.28-.774-1.482-1.361z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Telegram;
