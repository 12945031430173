import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { attachGaDataAttributes } from "../../utils/analytics";
import { IEvent } from "../../types/event";
import UICircularProgress from "../CircularProgress/CircularProgress";

const UIButton = React.forwardRef(
  (
    {
      appearance,
      icon,
      children,
      disabled,
      onClick,
      attributes,
      event,
      size,
      component: Root,
      fullWidth,
      classes,
      className,
      loading,
    },
    ref
  ) => {
    const { href, rel, target, ...otherAttributes } = attributes;
    const rootProps = {
      ...otherAttributes,
      ...attachGaDataAttributes(event),
    };

    if (disabled) {
      rootProps.disabled = disabled;
    }

    if (!disabled && href) {
      Root = "a";
      rootProps.href = href;
      rootProps.rel = rel;
      rootProps.target = target;
    }

    return (
      <Root
        className={cx(
          "UIButton",
          {
            "-primary": appearance === "primary",
            "-secondary": appearance === "secondary",
            "-transparent": appearance === "transparent",
            "-ghost-dark": appearance === "ghost-dark",
            "-ghost-light": appearance === "ghost-light",
            "-overlay": appearance === "overlay",

            "-small": size === "small",
            "-medium": size === "medium",
            "-icon": !!icon && !children,
            "-fullWidth": fullWidth,
            "-loading": loading,
          },
          className,
          classes.root
        )}
        data-testid="ui-button"
        onClick={onClick}
        ref={ref}
        {...rootProps}
      >
        {icon}
        {children && (
          <div
            className={cx("UIButton-content", classes.content)}
            data-testid="ui-button-content"
            data-button="content"
          >
            {children}
          </div>
        )}
        {loading && (
          <div className="UIButton-loading">
            <UICircularProgress classes={{ path: "UIButton-loading-path" }} />
          </div>
        )}
      </Root>
    );
  }
);

export default UIButton;

UIButton.propTypes = {
  appearance: PropTypes.oneOf([
    "primary",
    "secondary",
    "transparent",
    "ghost-light",
    "ghost-dark",
    "overlay",
  ]),
  size: PropTypes.oneOf(["medium", "small"]),
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  event: PropTypes.shape(IEvent).isRequired,
  attributes: PropTypes.object,
  component: PropTypes.elementType,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
  }),
};

UIButton.defaultProps = {
  appearance: "secondary",
  size: "medium",
  component: "button",
  fullWidth: false,
  loading: false,
  attributes: {},
  classes: {},
};
