import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import styles from './FooterMobile.module.scss';
import CurrencySwitcher, {
  CurrencySwitcherProps,
} from 'components/CurrencySwitcher';
import { useClassnames } from 'shared/useClassnames';
import cx from 'classnames';
import { LanguageIcon } from 'icons';
import { DataAttributes } from 'types/base';

export type FooterMobileProps = HTMLAttributes<HTMLDivElement> & {
  /** Copy text */
  copy?: ReactNode;
  /** Current language text */
  language?: ReactNode;
  languageIcon?: ReactNode;
  /** Terms & Conditions text */
  terms?: ReactNode;
  termsLink?: string;
  languageLink?: string;
  /** Current currency value */
  currency?: CurrencySwitcherProps['value'];
  currencies?: CurrencySwitcherProps['values'];
  onLanguageClick?: () => void;
  onCurrencyChange?: CurrencySwitcherProps['onChange'];
  TermsOptions?: DataAttributes &
    Partial<HTMLHyperlinkElementUtils> &
    HTMLAttributes<HTMLElement>;
  /** <a href="/currency-switcher">CurrencySwitcherProps</a>  */
  CurrencySwitcherOptions?: DataAttributes & CurrencySwitcherProps;
  LanguageOptions?: DataAttributes &
    Partial<HTMLHyperlinkElementUtils> &
    HTMLAttributes<HTMLElement>;
  classes?: typeof styles;
};

const FooterMobile = forwardRef<HTMLDivElement, FooterMobileProps>(
  (
    {
      className,
      classes = {},
      copy,
      terms,
      termsLink,
      language,
      languageIcon = <LanguageIcon />,
      languageLink,
      onLanguageClick = () => {},
      onCurrencyChange,
      currency,
      currencies,
      TermsOptions = {},
      CurrencySwitcherOptions = {},
      LanguageOptions = {},
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <footer ref={ref} className={cx(classNames.root, className)}>
        <div className={classNames.wrapper}>
          <section>
            <a href={termsLink} {...TermsOptions}>
              {terms}
            </a>
          </section>
          <section className={classNames.row}>
            <a
              href={languageLink}
              className={classNames.language}
              onClick={onLanguageClick}
              {...LanguageOptions}
            >
              {languageIcon}
              {language}
            </a>
            <CurrencySwitcher
              value={currency}
              values={currencies}
              onChange={onCurrencyChange}
              {...CurrencySwitcherOptions}
            />
          </section>
          <section>
            <div className={classNames.copy}>{copy}</div>
          </section>
        </div>
      </footer>
    );
  }
);

FooterMobile.displayName = 'FooterMobile';

export default FooterMobile;
