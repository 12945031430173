import React, {
  ElementType,
  HTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import cx from 'classnames';
import styles from './NavigationArrow.module.scss';
import { useClassnames } from 'shared/useClassnames';
import LeftIcon from '../../icons/left.svg';
import RightIcon from '../../icons/right.svg';

export type NavigationArrowProps = HTMLAttributes<HTMLElement> & {
  variant?: 'regular' | 'overlay';
  direction?: 'left' | 'right';
  className?: string;
  classes?: typeof styles;
  disabled?: boolean;
  component?: ElementType;
  icon?: ReactNode;
};

const NavigationArrow = forwardRef<any, NavigationArrowProps>(
  (
    {
      variant = 'regular',
      direction = 'left',
      className,
      classes = {},
      disabled = false,
      component: Root = 'button',
      icon,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <Root
        ref={ref}
        className={cx(
          classNames.root,
          {
            [classNames.overlay]: variant === 'overlay',
            [classNames.disabled]: disabled,
          },
          className
        )}
        disabled={disabled}
        {...props}
      >
        <div className={classNames.inner}>
          {icon || (direction === 'right' ? <RightIcon /> : <LeftIcon />)}
        </div>
      </Root>
    );
  }
);

NavigationArrow.displayName = 'NavigationArrow';

export default NavigationArrow;
