import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Typography = ({ component: Root, className, variant, children }) => {
  const variantClassName = `UITypography-${variant}`;
  return (
    <Root className={cx("UITypography", variantClassName, className)}>
      {children}
    </Root>
  );
};

export default Typography;

Typography.propTypes = {
  component: PropTypes.elementType,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "body",
    "link",
    "bold",
    "caption",
    "label",
    "button-bold",
    "button",
  ]),
  children: PropTypes.node,
};

Typography.defaultProps = {
  component: "span",
  variant: "body",
};
