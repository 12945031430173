import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

const UIFormRow = ({ children }) => <div className="UIFormRow">{children}</div>;

const UIFormGroup = ({ children, wrap }) => {
  return <div className={cx("UIFormGroup", { "-wrap": wrap })}>{children}</div>;
};

UIFormGroup.propTypes = {
  wrap: PropTypes.bool,
};

export default {
  Row: UIFormRow,
  Group: UIFormGroup,
};
