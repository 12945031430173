import React, { forwardRef, HTMLAttributes, useMemo, Ref } from 'react';
import cx from 'classnames';
import styles from './Checkbox.module.scss';
import mergeClasses from 'shared/mergeClasses';

export type CheckboxProps = Omit<HTMLAttributes<HTMLElement>, 'onChange'> & {
  label?: string;
  className?: string;
  classes?: typeof styles;
  checked: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  inputProps?: HTMLAttributes<HTMLInputElement>;
  inputRef?: Ref<HTMLInputElement>;
};

export type CheckboxRef = HTMLLabelElement;

const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  (
    {
      label,
      className,
      classes = {},
      checked,
      onChange = () => {},
      disabled = false,
      inputProps = {},
      inputRef,
      ...props
    },
    ref
  ) => {
    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);

    return (
      <label
        className={cx(
          classNames.root,
          { [classNames.disabled]: disabled },
          className
        )}
        {...props}
        ref={ref}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={() => onChange(!checked)}
          disabled={disabled}
          className={classNames.input}
          ref={inputRef}
          {...inputProps}
        />
        <span className={classNames.check} />
        {label && <div className={classNames.label}>{label}</div>}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
