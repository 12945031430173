import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';
import styles from './Footer.module.scss';

export type FooterProjectProps = Partial<HTMLHyperlinkElementUtils> &
  HTMLAttributes<HTMLElement> & {
    logo: ReactNode;
    className?: string;
    classes?: typeof styles;
  };

export const FooterProject = forwardRef<HTMLAnchorElement, FooterProjectProps>(
  ({ classes = {}, className, logo, children, href, ...props }, ref) => {
    const classNames = useClassnames(styles, classes);
    return (
      <a
        className={cx(classNames.project, className)}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref}
        {...props}
      >
        <div className={classNames.projectLogo}>{logo}</div>
        <div className={classNames.projectDescription}>{children}</div>
      </a>
    );
  }
);

FooterProject.displayName = 'FooterProject';
