import Menu from 'components/refresh/Menu';
import { PopupProps } from 'components/refresh/Popup';
import TextField, { TextFieldRefreshProps } from 'components/refresh/TextField';
import { InputAttributes, TextFieldRef } from 'components/TextField';
import React, {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import styles from './SelectRefresh.module.scss';
import cx from 'classnames';
import ArrowIcon from '../../../icons/triangle-arrow.svg';
import { SelectContext, SelectProvider } from './context';
import { useClassnames } from 'shared/useClassnames';

type MultipleProps =
  | {
      multiple?: false;
      value?: any;
    }
  | {
      multiple: true;
      value?: Array<any>;
    };

export type SelectProps = MultipleProps & {
  className?: string;
  onChange?: (value: any) => void;
  label?: string;
  TextFieldProps?: Partial<TextFieldRefreshProps>;
  InputProps?: Partial<InputAttributes>;
  inputRef?: Ref<TextFieldRef>;
  MenuProps?: Partial<PopupProps>;
  TriggerProps?: HTMLAttributes<HTMLDivElement>;
  renderValue?: (value: any) => ReactNode | string;
  children?: ReactNode;
  opened?: boolean;
  onOpenedChange?: (value: boolean) => void;
  closeOnChange?: boolean;
  classes?: typeof styles;
  headerTitle?: string;
  actions?: ReactNode;
  triggerElement?: ReactNode;
  defaultValue?: any;
  toggleTrigger?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

const SelectContent = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      renderValue,
      label,
      TextFieldProps = {},
      TriggerProps = {},
      InputProps = {},
      inputRef,
      children,
      MenuProps = {},
      className,
      classes = {},
      headerTitle,
      actions,
      triggerElement,
      multiple,
      defaultValue = null,
      toggleTrigger = false,
    },
    ref
  ) => {
    const {
      selected,
      onItemClick,
      opened,
      onOpenedChange: handleOpenedChange,
    } = useContext(SelectContext);
    const textFieldRef = useRef<HTMLDivElement>(null);
    const anchorRef = useRef<HTMLDivElement>(null);

    const classNames = useClassnames(styles, classes);

    useImperativeHandle(inputRef, () => {
      return textFieldRef.current as HTMLDivElement;
    });

    const renderedValue = useMemo(() => {
      if (renderValue) return renderValue(selected);
      if (Array.isArray(selected)) return selected.join(', ');
      if (!selected) return '';
      return selected.toString();
    }, [selected, renderValue]);

    const childrenArray = React.Children.toArray(children);

    const items = childrenArray.map(child => {
      if (!React.isValidElement(child)) return null;

      if (child.props.value === undefined) return child;

      const isChildSelected = multiple
        ? selected &&
          selected.find(
            (elem: any) => elem.toString() === child.props.value?.toString()
          )
        : child.props.value?.toString() !== defaultValue?.toString() &&
          selected?.toString() === child.props.value?.toString();

      return React.cloneElement(child as React.ReactElement<any>, {
        onClick: (event: MouseEvent) => {
          child.props.onClick?.(event);
          onItemClick(child);
        },
        selected: isChildSelected,
      });
    });

    return (
      <div ref={ref} className={cx(classNames.root, className)}>
        <div
          {...TriggerProps}
          ref={anchorRef}
          className={classNames.trigger}
          onClick={event => {
            handleOpenedChange(toggleTrigger ? !opened : true);
            if (TriggerProps.onClick) {
              TriggerProps.onClick(event);
            }
          }}
        >
          {triggerElement || (
            <TextField
              value={renderedValue}
              variant="outline"
              InputProps={{ readOnly: true, ...InputProps }}
              label={label}
              ref={textFieldRef}
              endIcon={<ArrowIcon />}
              {...TextFieldProps}
              classes={{
                ...TextFieldProps.classes,
                input: classNames.textfield,
                endIcon: cx(classNames.icon, {
                  [classNames.iconOpened]: opened,
                }),
              }}
            />
          )}
        </div>

        <Menu
          opened={opened}
          onClose={() => handleOpenedChange(false)}
          anchorEl={anchorRef.current}
          headerTitle={headerTitle || label}
          actions={actions}
          fullWidth
          {...MenuProps}
        >
          {items}
        </Menu>
      </div>
    );
  }
);

const Select = forwardRef<HTMLDivElement, SelectProps>((props, ref) => (
  <SelectProvider {...props}>
    <SelectContent {...props} ref={ref} />
  </SelectProvider>
));

SelectContent.displayName = 'SelectContentRefresh';

Select.displayName = 'SelectRefresh';

export default Select;
