import React, {
  CSSProperties,
  HTMLAttributes,
  ImgHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import cx from 'classnames';
import { Label, Typography } from '@flatfy/supernova-new';
import { LabelProps } from '@flatfy/supernova-new/types/components/Label';
import { CardData } from 'types/card';
import { DefaultCoverIcon, WarningRedIcon } from 'icons';
import FavoriteButton, { FavoriteButtonProps } from 'components/FavoriteButton';
import Image from 'components/Image';
import Countdown, { CountdownProps } from 'components/Countdown';
import { useClassnames } from 'shared/useClassnames';
import CardDeveloper from './CardDeveloper';
import CardLocation from './CardLocation';
import styles from './Card.module.scss';

export type CardProps = HTMLAttributes<HTMLDivElement> &
  CardData & {
    classes?: typeof styles;
    className?: string;
    countdown?: boolean;
    withLogoCover?: boolean;
    LinkAttributes?: HTMLAttributes<HTMLAnchorElement>;
    ImageAttributes?: ImgHTMLAttributes<HTMLImageElement>;
    FavoriteButtonProps?: Partial<FavoriteButtonProps>;
    favoriteButton?: ReactNode;
    LabelProps?: Partial<LabelProps>;
    ActionLabelProps?: Partial<LabelProps>;
    CountdownProps?: CountdownProps;
    children?: ReactNode;
    headerChildren?: ReactNode;
    gridSpacing?: string;
    isFavorite?: boolean;
    onFavoriteChange?: FavoriteButtonProps['onChange'];
  };

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      classes = {},
      className,
      FavoriteButtonProps = {},
      price,
      developer,
      otherCompanies = { logos: [] },
      label,
      name,
      isCottage = false,
      actionLabel,
      location,
      hideName = false,
      cover,
      cover2x,
      logo,
      brandColor,
      withLogoCover = false,
      url,
      isDouble,
      coverPlaceholder = <DefaultCoverIcon />,
      LinkAttributes = {},
      ImageAttributes = {},
      LabelProps = {},
      ActionLabelProps = {},
      locationDetails,
      countdown = false,
      CountdownProps,
      children,
      headerChildren,
      gridSpacing,
      isRisky = false,
      isCritical = false,
      isFavorite,
      onFavoriteChange,
      favoriteButton,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);

    const bottomContent = (
      <div className={classNames.bottom}>
        <div className={classNames.row}>
          {(developer ||
            otherCompanies.logos.length > 0 ||
            otherCompanies.names) && (
            <div className={classNames.developer}>
              {developer && developer.isPremium ? (
                <CardDeveloper data={developer} classes={classes} />
              ) : (
                <>
                  {otherCompanies.logos.length > 0 ? (
                    <>
                      {otherCompanies.logos.map(developerItem => (
                        <CardDeveloper
                          key={developerItem.id}
                          data={developerItem}
                          classes={classes}
                        />
                      ))}
                    </>
                  ) : (
                    <div className={classNames.text}>
                      {developer?.name || otherCompanies?.names}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {isRisky && (
            <div className={classNames.warning}>
              <WarningRedIcon />
            </div>
          )}
        </div>

        <div className={classNames.title}>
          {!hideName && (
            <Typography variant="h3" className={classNames.h3}>
              {name}
            </Typography>
          )}
          <div className={cx(classNames.text, classNames.location)}>
            {location}
          </div>
        </div>
        {price && <div className={classNames.price}>{price}</div>}
        {locationDetails && (
          <CardLocation
            data={locationDetails}
            isCottage={isCottage}
            classes={classes}
          />
        )}
        {children}
      </div>
    );

    return (
      <div
        className={cx(
          classNames.root,
          { [classNames.doubled]: isDouble },
          className
        )}
        ref={ref}
        style={
          {
            '--brand-color': brandColor,
            '--grid-spacing': gridSpacing,
          } as CSSProperties
        }
        {...props}
      >
        <div className={classNames.inner}>
          {logo && withLogoCover ? (
            <Image
              src={logo}
              alt={name}
              className={cx(classNames.imageLogo)}
              {...ImageAttributes}
            />
          ) : (
            <>
              {cover ? (
                <Image
                  src={cover}
                  src2x={cover2x}
                  alt={name}
                  className={classNames.picture}
                  useWebp
                  lazy
                  {...ImageAttributes}
                />
              ) : (
                <div className={classNames.picture}>
                  <div className={classNames.coverPlaceholder}>
                    {coverPlaceholder}
                  </div>
                </div>
              )}
            </>
          )}
          <div className={classNames.cover}>
            {isCritical && <div className={classNames.critical} />}
            <div className={classNames.header}>
              {label && (
                <Label
                  variant="accent"
                  size="small"
                  className={styles.label}
                  style={{
                    background: label.color,
                  }}
                  {...LabelProps}
                >
                  <span className={styles.text}>{label.text}</span>
                </Label>
              )}
              {actionLabel && (
                <Label
                  variant="accent"
                  size="small"
                  className={cx(styles.label, styles.actionLabel)}
                  {...ActionLabelProps}
                >
                  <span className={styles.text}>{actionLabel}</span>
                </Label>
              )}
              {countdown && CountdownProps && <Countdown {...CountdownProps} />}
              {headerChildren}
            </div>
            {isDouble ? (
              <div className={classNames.doubledBottomWrapper}>
                {bottomContent}
                {logo && (
                  <div className={classNames.doubledLogo}>
                    <Image src={logo} alt={name} />
                  </div>
                )}
              </div>
            ) : (
              bottomContent
            )}
          </div>
        </div>
        <div className={classNames.favorite}>
          {favoriteButton || (
            <FavoriteButton
              variant="ghost-light"
              value={isFavorite}
              onChange={onFavoriteChange}
              {...FavoriteButtonProps}
            />
          )}
        </div>
        <a className={classNames.link} href={url} {...LinkAttributes} />
      </div>
    );
  }
);

Card.displayName = 'Card';

export default Card;
