import React, { ImgHTMLAttributes, useMemo } from 'react';

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  className?: string;
  useWebp?: boolean;
  src2x?: string;
  lazy?: boolean;
  srcWebp?: string;
  src2xWebp?: string;
};

const Image = ({
  src,
  src2x,
  useWebp = false,
  className,
  lazy = false,
  srcWebp,
  src2xWebp,
  ...props
}: ImageProps) => {
  const srcSet = useMemo(
    () => (src2x ? `${src} 1x, ${src2x} 2x` : undefined),
    [src2x, src]
  );
  const srcSetWebp = useMemo(() => {
    const srcSource = srcWebp || `${src}.webp`;
    const src2xSource = src2xWebp || (src2x ? `${src2x}.webp` : `${src}.webp`);
    return src2xWebp || src2x
      ? `${srcSource} 1x, ${src2xSource} 2x`
      : srcSource;
  }, [src2x, src, srcWebp, src2xWebp]);

  if (useWebp) {
    return (
      <picture className={className} {...props}>
        <source srcSet={srcSetWebp} type="image/webp" />
        <img
          alt={props.alt}
          src={src}
          srcSet={srcSet}
          loading={lazy ? 'lazy' : 'eager'}
        />
      </picture>
    );
  }

  return <img className={className} src={src} srcSet={srcSet} {...props} />;
};

export default Image;
