import React, { createContext, ReactNode, useState } from 'react';

type TabStyle = {
  width: number;
  left: number;
};

export type TabsContextType = {
  activeTabStyle: TabStyle;
  updateTabStyle: (value: TabStyle) => void;
};

export const TabsContext = createContext<TabsContextType | null>(null);

const TabsContextProvider = ({ children }: { children: ReactNode }) => {
  const [activeTabStyle, setActiveTabStyle] = useState<TabStyle>({
    width: 0,
    left: 0,
  });

  const updateTabStyle = (value: TabStyle) => {
    setActiveTabStyle(value);
  };

  return (
    <TabsContext.Provider value={{ activeTabStyle, updateTabStyle }}>
      {children}
    </TabsContext.Provider>
  );
};

export default TabsContextProvider;
