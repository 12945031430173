import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';
import styles from './MenuRefresh.module.scss';

export type MenuListProps = HTMLAttributes<HTMLElement> & {
  className?: string;
  children?: ReactNode;
};

const MenuList = forwardRef<HTMLDivElement, MenuListProps>(
  ({ children, className, ...props }, ref) => (
    <div className={cx(styles.root, className)} {...props} ref={ref}>
      {children}
    </div>
  )
);

MenuList.displayName = 'MenuListRefresh';

export default MenuList;
