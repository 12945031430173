import React from "react";

export default () => (
  <svg
    className="ico"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#505050"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.4082 16.775L6.8832 18.25L15.1332 10L6.8832 1.75L5.4082 3.225L12.1832 10L5.4082 16.775Z" />
  </svg>
);
