import React, {
  ElementType,
  HTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';
import { CircularProgress } from 'components';
import LeftIcon from '../../../icons/refresh/left.svg';
import RightIcon from '../../../icons/refresh/right.svg';
import styles from './NavigationArrowRefresh.module.scss';

export type NavigationArrowProps = HTMLAttributes<HTMLElement> & {
  direction?: 'left' | 'right';
  className?: string;
  classes?: typeof styles;
  disabled?: boolean;
  loading?: boolean;
  component?: ElementType;
  icon?: ReactNode;
};

const NavigationArrow = forwardRef<any, NavigationArrowProps>(
  (
    {
      direction = 'left',
      className,
      classes = {},
      disabled = false,
      loading = false,
      component: Root = 'button',
      icon,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <Root
        ref={ref}
        className={cx(
          classNames.root,
          {
            [classNames.disabled]: disabled,
            [classNames.loading]: loading,
          },
          className
        )}
        disabled={disabled || loading}
        {...props}
      >
        <div className={classNames.content}>
          <div className={classNames.icon}>
            {icon || (direction === 'right' ? <RightIcon /> : <LeftIcon />)}
          </div>
          {loading && (
            <CircularProgress
              className={classNames.circularProgress}
              size={16}
            />
          )}
        </div>
      </Root>
    );
  }
);

NavigationArrow.displayName = 'NavigationArrow';

export default NavigationArrow;
