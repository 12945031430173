import React, { ElementType, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';
import styles from './TypographyRefresh.module.scss';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'subtitle1'
  | 'subtitle2'
  | 'caption'
  | 'button'
  | 'tiny'
  | 'input'
  | 'menu';

export type TypographyProps = HTMLAttributes<HTMLElement> & {
  component?: ElementType;
  className?: string;
  variant?: TypographyVariant;
  variantMd?: TypographyVariant;
  variantSm?: TypographyVariant;
  children: ReactNode;
  gutterBottom?: boolean;
};

const Typography = ({
  component: Root = 'span',
  className,
  variant = 'body1',
  variantSm,
  variantMd,
  children,
  gutterBottom = false,
  ...props
}: TypographyProps) => (
  <Root
    className={cx(
      styles.root,
      styles[variant],
      variantMd && styles[variantMd],
      variantSm && styles[variantSm],
      {
        [styles.gutterBottom]: gutterBottom,
      },
      className
    )}
    {...props}
  >
    {children}
  </Root>
);

export default Typography;
