import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { attachGaDataAttributes } from "../../utils/analytics";
import { IEvent } from "../../types/event";
import TelegramIcon from "../../icons/Telegram";

const UITelegramButton = ({
  children,
  attributes,
  onClick,
  event,
  component: Root,
  fullWidth,
}) => {
  const rootProps = {
    ...attributes,
    ...attachGaDataAttributes(event),
  };

  if (attributes.href) {
    Root = "a";
  }

  return (
    <Root
      className={cx("UITelegramButton", {
        "-fullWidth": fullWidth,
      })}
      data-button="content"
      onClick={onClick}
      {...rootProps}
    >
      <div className="UITelegramButton-content">{children}</div>
      <div className="UITelegramButton-icon">
        <TelegramIcon />
      </div>
    </Root>
  );
};

export default UITelegramButton;

UITelegramButton.propTypes = {
  component: PropTypes.elementType,
  event: PropTypes.shape(IEvent).isRequired,
  attributes: PropTypes.object,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
};

UITelegramButton.defaultProps = {
  component: "button",
  modifiers: {},
  attributes: {},
  fullWidth: false,
};
