import React from 'react';
import * as Icon from '../../icons/app-links';

const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.lun.mylun&hl=uk&gl=US';
const APP_STORE_LINK =
  'https://apps.apple.com/ua/app/%D0%BB%D1%83%D0%BD/id1508320534?l=uk';

export enum AppLinksType {
  Android = 'android',
  Ios = 'ios',
}

export const APP_LINKS_DATA = {
  [AppLinksType.Android]: {
    logoIcon: <Icon.GooglePlayLogoIcon />,
    textIcon: <Icon.GooglePlayTextIcon />,
    href: GOOGLE_PLAY_LINK,
  },
  [AppLinksType.Ios]: {
    logoIcon: <Icon.AppStoreLogoIcon />,
    textIcon: <Icon.AppStoreTextIcon />,
    href: APP_STORE_LINK,
  },
};
