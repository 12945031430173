import React, { ElementType, HTMLAttributes, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Typography.module.scss';

export type TypographyProps = HTMLAttributes<HTMLElement> & {
  component?: ElementType;
  className?: string;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'body'
    | 'bold'
    | 'caption'
    | 'label'
    | 'button-bold'
    | 'button';
  children: ReactNode;
  gutterBottom?: boolean;
};

const Typography = ({
  component: Root = 'span',
  className,
  variant = 'body',
  children,
  gutterBottom = false,
  ...other
}: TypographyProps) => (
  <Root
    className={cx(styles.root, className, {
      [styles.h1]: variant === 'h1',
      [styles.h2]: variant === 'h2',
      [styles.h3]: variant === 'h3',
      [styles.h4]: variant === 'h4',
      [styles.body]: variant === 'body',
      [styles.bold]: variant === 'bold',
      [styles.label]: variant === 'label',
      [styles.button]: variant === 'button',
      [styles.caption]: variant === 'caption',
      [styles.buttonBold]: variant === 'button-bold',
      [styles.gutterBottom]: gutterBottom,
    })}
    {...other}
  >
    {children}
  </Root>
);

export default Typography;
