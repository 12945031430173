import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import UIButton from "../Button/Button";
import Close from "../../icons/Close";

const UIModal = ({
  opened,
  classes,
  children,
  closeButton,
  analytics,
  onClose,
  modifiers,
  width,
}) => {
  const contentWrapperElement = useRef();

  const handleOverlayClick = (event) => {
    if (
      contentWrapperElement.current &&
      contentWrapperElement.current.contains(event.target)
    )
      return;
    onClose();
  };

  useEffect(() => {
    if (opened) {
      document.body.classList.add("UIModal-no-scroll");
    } else {
      document.body.classList.remove("UIModal-no-scroll");
    }
  }, [opened]);

  return (
    <div
      className={cx(
        "UIModal",
        {
          "-opened": opened,
          "-centered": modifiers.centered,
          "-padding": modifiers.padding,
          "-fixed-width": width,
        },
        classes && classes.modal
      )}
      tabIndex="-1"
      onClick={handleOverlayClick}
      style={{ "--width": width }}
    >
      <div
        className={cx(
          "UIModal-content",
          {
            "-opened": opened && !(classes && classes.contentOpened),
          },
          classes && classes.content,
          opened && classes && classes.contentOpened
        )}
        role="dialog"
        aria-modal="true"
        ref={contentWrapperElement}
      >
        <div>{children}</div>
        {closeButton && (
          <div className="UIModal-close">
            <UIButton
              event={{ ...analytics, label: "close_modal" }}
              onClick={onClose}
              appearance="ghost-light"
              size="small"
              icon={<Close />}
            />
          </div>
        )}
      </div>
    </div>
  );
};

UIModal.propTypes = {
  analytics: PropTypes.object.isRequired,
  opened: PropTypes.bool,
  classes: PropTypes.object,
  closeButton: PropTypes.bool,
  modifiers: PropTypes.shape({
    centered: PropTypes.bool,
    padding: PropTypes.bool,
  }),
  width: PropTypes.string,
};

UIModal.defaultProps = {
  modifiers: {},
};

export default UIModal;
