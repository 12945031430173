import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { IEvent } from "../../types/event";
import UICheckbox from "../Checkbox/Checkbox";
import { attachGaDataAttributes } from "../../utils/analytics";

const noop = () => {};

const UIOption = ({ icon, content, classes = {} }) => (
  <>
    {icon && icon.left && (
      <div className={cx("UICell-icon -left", classes.icon)}>{icon.left}</div>
    )}
    <div className={cx("UICell-content", classes.content)}>{content}</div>
    {icon && icon.right && (
      <div className={cx("UICell-icon -right", classes.icon)}>{icon.right}</div>
    )}
  </>
);

const UICell = ({
  onChange = noop,
  component: Option = UIOption,
  className,
  TagName = "div",
  rootAttributes = {},
  ...attributes
}) => {
  const {
    value,
    content,
    selected,
    disabled,
    nonInteractive,
    event,
    classes,
  } = attributes;

  return (
    <TagName
      className={cx(
        "UICell",
        {
          "-selected": selected,
          "-disabled": disabled,
          "-non-interactive": nonInteractive,
        },
        className,
        classes?.root
      )}
      onClick={() => {
        if (disabled) return;
        onChange({ value, content });
      }}
      {...attachGaDataAttributes(event)}
      {...rootAttributes}
    >
      <Option {...attributes} />
    </TagName>
  );
};

const UICheckboxCell = ({ onChange = noop, className, ...attributes }) => {
  const { value, content, selected, disabled } = attributes;

  return (
    <UICheckbox
      className={cx("UICell", className)}
      checked={selected}
      disabled={disabled}
      onChange={(event) => {
        onChange({ value, content, checked: event.target.checked });
      }}
      {...attributes}
    />
  );
};

const UISection = ({
  title,
  value,
  disabled,
  selected,
  event,
  withCheckbox,
  children,
  options = [],
  onChange = noop,
  className,
  classes = {},
}) => {
  const [checkedSection, setCheckedSection] = useState(selected);

  const checkboxOnChange = ({ checked, content, value }) => {
    const optionsToChange = options.filter(
      (o) => o.selected !== checked && !o.disabled && !o.nonInteractive
    );

    if (optionsToChange.length > 0) {
      optionsToChange.forEach((o) => {
        onChange({
          value: o.value,
          content: o.content,
          checked,
        });
      });
    } else {
      onChange({
        value,
        content,
        checked,
      });
    }
  };

  const cellOnChange = ({ value, content, checked }) => {
    const allOptionsSelected = options
      .filter((o) => o.value !== value)
      .every((o) => o.selected || o.disabled);

    if (checkedSection) {
      setCheckedSection(false);
    } else if (allOptionsSelected) {
      setCheckedSection(true);
    }
    onChange({
      value,
      content,
      checked,
    });
  };

  useEffect(() => {
    setCheckedSection(selected);
  }, [selected]);

  return (
    <div
      className={cx(
        "UISection",
        {
          "-checkbox-with-title": title && withCheckbox,
        },
        className,
        classes.root
      )}
    >
      <div className={cx("UISection-header", classes.header)}>
        {title && withCheckbox && (
          <UICheckboxCell
            className={cx("UISection-title", classes.title)}
            content={title}
            event={event}
            disabled={disabled}
            selected={checkedSection}
            value={value}
            onChange={checkboxOnChange}
          />
        )}
        {title && !withCheckbox && (
          <div className={cx("UISection-title", classes.title)}>{title}</div>
        )}
        {(options.length > 0 || children) && (
          <div className={cx("UISection-divider", classes.divider)} />
        )}
      </div>
      {options.map((option) => {
        if (withCheckbox && !option.nonInteractive) {
          return (
            <UICheckboxCell
              {...option}
              key={option.key || option.value}
              onChange={cellOnChange}
            />
          );
        }

        return (
          <UICell
            {...option}
            key={option.key || option.value}
            onChange={onChange}
          />
        );
      })}
      {children}
    </div>
  );
};

const UIMenu = ({ type, options, withCheckbox, onChange, children }) => (
  <>
    {options?.map((option) => {
      if (type === "sections") {
        return (
          <UISection
            {...option}
            withCheckbox={withCheckbox}
            key={option.key || option.title}
            onChange={onChange}
          />
        );
      }

      if (withCheckbox && !option.nonInteractive) {
        return (
          <UICheckboxCell
            {...option}
            key={option.key || option.value}
            onChange={onChange}
          />
        );
      }

      return (
        <UICell
          {...option}
          key={option.key || option.value}
          onChange={onChange}
        />
      );
    })}
    {children}
  </>
);

const cellPropTypes = {
  event: PropTypes.shape(IEvent).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  component: PropTypes.func,
  attributes: PropTypes.object,
  nonInteractive: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    content: PropTypes.string,
  }),
};

const optionsPropTypes = PropTypes.arrayOf(PropTypes.shape(cellPropTypes));

UIOption.propTypes = {
  icon: PropTypes.shape({
    left: PropTypes.element,
    right: PropTypes.element,
  }),
  content: PropTypes.string,
  classes: cellPropTypes.classes,
};

UICell.propTypes = {
  ...cellPropTypes,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

UISection.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  options: optionsPropTypes,
  event: PropTypes.shape(IEvent),
  onChange: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    header: PropTypes.string,
    divider: PropTypes.string,
    title: PropTypes.string,
  }),
};

UIMenu.propTypes = {
  type: PropTypes.string,
  withCheckbox: PropTypes.bool,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        disabled: PropTypes.bool,
        selected: PropTypes.bool,
        options: optionsPropTypes,
        event: PropTypes.shape(IEvent),
      })
    ) /* menu with sections */,
    optionsPropTypes /* menu without sections */,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.element,
};

UIMenu.Option = UIOption;
UIMenu.Cell = UICell;
UIMenu.Section = UISection;

export default UIMenu;
