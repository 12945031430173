import React, { ReactNode, useMemo, CSSProperties } from 'react';
import cx from 'classnames';
import styles from './Grid.module.scss';

const GRID_COLS = Array.from({ length: 12 }).map((_, i) => i + 1);

export type GridColSize = (typeof GRID_COLS)[number] | string;

export type GridContainerProps = {
  wrap?: boolean;
  children?: ReactNode;
  scroll?: true | 'sm' | 'md';
  className?: string;
  alignItems?: 'flex-end' | 'flex-start' | 'center';
  justifyContent?: 'flex-end' | 'flex-start' | 'center';
  spaceBetween?: number;
};

export type GridItemProps = {
  col?: GridColSize;
  sm?: GridColSize;
  md?: GridColSize;
  children?: ReactNode;
  className?: string;
};

const colValueStringCheck = (value?: GridColSize) =>
  typeof value === 'string' && value.endsWith('px');

const GridContainer = ({
  wrap,
  children,
  scroll,
  className,
  alignItems = 'center',
  justifyContent = 'flex-start',
  spaceBetween = 12,
}: GridContainerProps) => (
  <div
    className={cx(
      styles.container,
      {
        [styles.wrap]: wrap,
        [styles.scroll]: scroll === true,
        [styles.scrollSm]: scroll === 'sm',
        [styles.scrollMd]: scroll === 'md',
      },
      className
    )}
    style={
      {
        '--alignItems': alignItems,
        '--justifyContent': justifyContent,
        '--spaceBetween': `${spaceBetween}px`,
      } as CSSProperties
    }
  >
    {children}
  </div>
);

const GridItem = ({ col, sm, md, children, className }: GridItemProps) => {
  const classes = useMemo(() => {
    const result: string[] = [];
    GRID_COLS.forEach(value => {
      if (col === value) result.push(styles[`col${value}`]);
      if (sm === value) result.push(styles[`colSm${value}`]);
      if (md === value) result.push(styles[`colMd${value}`]);
    });
    return result;
  }, [col, sm, md]);

  const style = useMemo(() => {
    let result = {} as React.CSSProperties;

    if (colValueStringCheck(col)) {
      result = {
        ...result,
        '--col-width': col,
      } as React.CSSProperties;
    }
    if (colValueStringCheck(md)) {
      result = {
        ...result,
        '--md-col-width': md,
      } as React.CSSProperties;
    }
    if (colValueStringCheck(sm)) {
      result = {
        ...result,
        '--sm-col-width': sm,
      } as React.CSSProperties;
    }
    return result;
  }, [col, sm, md]);

  return (
    <div
      className={cx(
        styles.col,
        ...classes,
        {
          [styles.colSized]: colValueStringCheck(col),
          [styles.colSizedMd]: colValueStringCheck(md),
          [styles.colSizedSm]: colValueStringCheck(sm),
        },
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
};

const Grid = {
  Container: GridContainer,
  Item: GridItem,
};

export default Grid;
