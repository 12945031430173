import React, {
  Fragment,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './NavigationCitySelect.module.scss';
import { useDebounce } from 'react-use';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';
import * as Icons from 'icons';
import {
  MenuList,
  Popup,
  PopupContent,
  RefreshComponents,
} from '@flatfy/supernova-new';
import { PopupProps } from '@flatfy/supernova-new/types/components/Popup';
import { MenuItemProps } from '@flatfy/supernova-new/types/components/MenuItem';
import { TextFieldProps } from '@flatfy/supernova-new/types/components/TextField';
import { DataAttributes } from 'types/base';

export type NavigationCitySelectListItem = {
  value: string | number;
  label: string;
  link?: string;
};

type NavigationCitySelectMenuItemProps =
  | ({
      href?: never;
    } & Partial<MenuItemProps<HTMLDivElement>>)
  | (Omit<Partial<MenuItemProps<HTMLAnchorElement>>, 'href'> & {
      href: string;
    });

export type NavigationCitySelectRenderMenuItemProps = Omit<
  NavigationCitySelectMenuItemProps,
  'onChange'
> & {
  value: NavigationCitySelectListItem;
  onChange: (value: NavigationCitySelectListItem) => void;
  selected: boolean;
};

export type NavigationCitySelectProps = {
  premiumList?: NavigationCitySelectListItem[];
  basicList?: NavigationCitySelectListItem[];
  onSearchChange?: (value: string) => void;
  onDebouncedSearchChange?: (value: string) => void;
  onChange?: (value: NavigationCitySelectListItem) => void;
  additionalText?: string;
  onAdditionalTextChange?: (value: string) => void;
  debounceMs?: number;
  initialSearchValue?: string;
  placeholder?: string;
  className?: string;
  classes?: typeof styles;
  PopupOptions?: DataAttributes & Partial<PopupProps>;
  TextFieldOptions?: DataAttributes & Partial<TextFieldProps>;
  MenuItemOptions?: DataAttributes & Partial<MenuItemProps<HTMLElement>>;
  autocompleteFn: (
    searchValue: string
  ) => Promise<NavigationCitySelectListItem[]>;
  value?: NavigationCitySelectListItem;
  renderMenuItem?: (
    props: NavigationCitySelectRenderMenuItemProps
  ) => ReactNode;
};

const renderMenuItemFn = ({
  value,
  onChange,
  ...props
}: NavigationCitySelectRenderMenuItemProps) =>
  value && (
    <RefreshComponents.MenuItem
      key={value.value}
      onClick={() => onChange(value)}
      component={value.link ? 'a' : 'div'}
      href={value.link}
      {...props}
      classes={{
        root: styles.menuItem,
        selected: styles.menuItemSelected,
        disabled: styles.menuItemDisabled,
        content: styles.menuItemContent,
        withCheckbox: styles.menuItemWithCheckbox,
        ...(props.classes || {}),
      }}
    >
      {value.label}
    </RefreshComponents.MenuItem>
  );

const NavigationCitySelect = forwardRef<
  HTMLDivElement,
  NavigationCitySelectProps
>(
  (
    {
      onSearchChange = () => {},
      onDebouncedSearchChange = () => {},
      initialSearchValue = '',
      autocompleteFn,
      debounceMs = 250,
      premiumList = [],
      basicList = [],
      classes = {},
      className,
      placeholder = '',
      onChange = () => {},
      value,
      additionalText,
      onAdditionalTextChange = () => {},
      renderMenuItem = renderMenuItemFn,
      MenuItemOptions = {},
      TextFieldOptions = {},
      PopupOptions = {},
    },
    ref
  ) => {
    const [searchValue, setSearchValue] = useState(initialSearchValue);
    const [debouncedSearchValue, setDebouncedSearchValue] =
      useState(initialSearchValue);
    const [opened, setOpened] = useState(false);
    const fieldAnchorRef = useRef<HTMLDivElement>(null);
    const [suggestions, setSuggestions] = useState<
      NavigationCitySelectListItem[]
    >([]);
    const classNames = useClassnames(styles, classes);

    useDebounce(
      () => {
        setDebouncedSearchValue(searchValue);
        onDebouncedSearchChange(searchValue);
      },
      debounceMs,
      [searchValue]
    );

    const fetchAutocomplete = async () => {
      if (searchValue.trim().length === 0) {
        setSuggestions([]);
        return;
      }

      try {
        const res = await autocompleteFn(searchValue);
        setSuggestions(res);
      } catch (err) {
        console.error(err);
      }
    };

    const handleFieldClick = () => {
      if (opened) return;
      setOpened(true);
    };

    const handleMenuItemClick = (item: NavigationCitySelectListItem) => {
      onChange(item);
      setOpened(false);
    };

    useEffect(() => {
      fetchAutocomplete();
      onDebouncedSearchChange(searchValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchValue]);

    useEffect(() => {
      onSearchChange(searchValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const renderList = (list: NavigationCitySelectListItem[]) => (
      <>
        {list.map(item => (
          <Fragment key={item.value}>
            {renderMenuItem({
              ...MenuItemOptions,
              value: item,
              onChange: handleMenuItemClick,
              selected: value?.value === item.value,
            })}
          </Fragment>
        ))}
      </>
    );

    return (
      <div className={cx(classNames.root, className)} ref={ref}>
        <div
          className={cx(classNames.field, { [classNames.fieldOpened]: opened })}
          ref={fieldAnchorRef}
          onClick={handleFieldClick}
        >
          <div className={classNames.back} onClick={() => setOpened(false)}>
            <Icons.ArrowLeftLongIcon />
          </div>
          <RefreshComponents.TextField
            value={
              opened && !searchValue
                ? ''
                : searchValue || (value && value.label)
            }
            placeholder={value ? value.label : placeholder}
            onChange={setSearchValue}
            size="small"
            endIcon={
              <>
                <Icons.ArrowDownTriangleIcon />
                <Icons.SearchIcon />
              </>
            }
            additionalText={additionalText}
            {...TextFieldOptions}
            classes={{
              root: classNames.textField,
              input: styles.textFieldInput,
              icon: styles.textFieldIcon,
              endIcon: styles.textFieldEndIcon,
              active: styles.textFieldActive,
              additional: styles.textFieldAdditionalText,
              ...(TextFieldOptions.classes || {}),
            }}
          />
        </div>
        <Popup
          anchorEl={fieldAnchorRef.current}
          opened={opened}
          onClose={() => setOpened(false)}
          maxHeight={442}
          {...PopupOptions}
          classes={{
            root: classNames.popup,
            backdrop: styles.backdrop,
            enterDone: styles.popupTransitioned,
            opened: styles.popupOpened,
            ...(PopupOptions.classes || {}),
          }}
        >
          <PopupContent className={classNames.popupContent}>
            <MenuList className={classNames.menuList}>
              {suggestions.length === 0 ? (
                <>
                  {premiumList.length > 0 && (
                    <>
                      {renderList(premiumList)}
                      <hr className={classNames.divider} />
                    </>
                  )}
                  {renderList(basicList)}
                </>
              ) : (
                renderList(suggestions)
              )}
            </MenuList>
          </PopupContent>
        </Popup>
      </div>
    );
  }
);

NavigationCitySelect.displayName = 'NavigationCitySelect';

export default NavigationCitySelect;
