import React, { ReactNode, HTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';
import styles from './LabelRefresh.module.scss';

export type LabelProps = HTMLAttributes<HTMLElement> & {
  children?: ReactNode;
  color?: 'green' | 'black' | 'gray';
  className?: string;
};

const Label = forwardRef<HTMLDivElement, LabelProps>(
  ({ children, color = 'black', className, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        styles.root,
        {
          [styles.black]: color === 'black',
          [styles.green]: color === 'green',
          [styles.gray]: color === 'gray',
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);

Label.displayName = 'LabelRefresh';

export default Label;
