import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import styles from './Alert.module.scss';
import { useClassnames } from 'shared/useClassnames';
import SuccessIcon from './icons/success.svg';
import InfoIcon from './icons/info.svg';
import CloseIcon from '../../../icons/refresh/close.svg';
import Button from '../Button';
import { useMeasure } from 'react-use';

export type AlertProps = HTMLAttributes<HTMLElement> & {
  variant?: 'success' | 'warning';
  icon?: ReactNode;
  action?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
  classes?: typeof styles;
  className?: string;
};

const ONE_LINE_HEIGHT = 22;

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      variant = 'success',
      icon,
      action,
      children,
      onClose,
      classes = {},
      className,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    const [contentRef, { height }] = useMeasure<HTMLDivElement>();

    return (
      <div
        className={cx(
          classNames.root,
          {
            [classNames.success]: variant === 'success',
            [classNames.warning]: variant === 'warning',
            [classNames.oneLine]: height <= ONE_LINE_HEIGHT,
          },
          className
        )}
        ref={ref}
        {...props}
      >
        <div className={classNames.icon}>
          {icon || (
            <>
              {variant === 'success' && <SuccessIcon />}
              {variant === 'warning' && <InfoIcon />}
            </>
          )}
        </div>
        <div className={classNames.content} ref={contentRef}>
          {children}
        </div>
        {(action || onClose) && (
          <div className={classNames.action}>
            {action || (
              <Button
                variant="secondary-icon"
                leftIcon={<CloseIcon />}
                onClick={onClose}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

Alert.displayName = 'AlertRefresh';

export default Alert;
