import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import styles from './BottomNavigation.module.scss';
import { useClassnames } from 'shared/useClassnames';

export type BottomNavigationProps = HTMLAttributes<HTMLElement> & {
  children?: ReactNode;
  className?: string;
  classes?: typeof styles;
};

const BottomNavigation = forwardRef<HTMLDivElement, BottomNavigationProps>(
  ({ children, className, classes = {}, ...props }, ref) => {
    const classNames = useClassnames(styles, classes);
    return (
      <div ref={ref} className={cx(classNames.root, className)} {...props}>
        <div className={classNames.floating}>
          <div className={classNames.content}>{children}</div>
        </div>
      </div>
    );
  }
);

BottomNavigation.displayName = 'BottomNavigation';

export default BottomNavigation;
