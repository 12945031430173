import React, { forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react';
import cx from 'classnames';
import TelegramIcon from '../../icons/telegram.svg';
import styles from './TelegramButton.module.scss';
import mergeClasses from '../../shared/mergeClasses';

export type TelegramButtonProps = HTMLAttributes<HTMLElement> & {
  href?: string;
  classes?: typeof styles;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  children?: ReactNode;
};

const TelegramButton = forwardRef<any, TelegramButtonProps>(
  (
    {
      href,
      classes = {},
      disabled = false,
      fullWidth = false,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const Root = href ? 'a' : 'button';

    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);

    return (
      <Root
        ref={ref}
        className={cx(
          classNames.root,
          {
            [classNames.fullWidth]: fullWidth,
            [classNames.disabled]: disabled,
          },
          className
        )}
        href={href}
        {...props}
      >
        <div className={classNames.content}>{children}</div>
        <div className={classNames.icon}>
          <TelegramIcon />
        </div>
      </Root>
    );
  }
);

TelegramButton.displayName = 'TelegramButton';

export default TelegramButton;
