import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import UIChip from "../Chip/Chip";
import IconArrowForward from "../../icons/ArrowForward";

const UIMainTitle = ({ title, withSubtitle, withChip, chip }) => {
  return (
    <div
      className={cx("UIMainTitle", {
        "-with-subtitle": withSubtitle,
        "-with-chip": withChip,
      })}
    >
      {title.size === "h1" ? (
        <h1 className="h1" data-testid={title.data_testid}>
          {title.content}
        </h1>
      ) : (
        <div className="h2" data-testid={title.data_testid}>
          {title.content}
        </div>
      )}
      {withChip && (
        <UIChip
          type={
            chip.withLink ? (chip.children ? "icon-right" : "icon") : "text"
          }
          icon={chip.withLink && <IconArrowForward />}
          {...chip}
        />
      )}
    </div>
  );
};

export default UIMainTitle;

UIMainTitle.propTypes = {
  title: PropTypes.shape({
    size: PropTypes.oneOf(["h1"]),
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
    data_testid: PropTypes.string,
  }).isRequired,
  withSubtitle: PropTypes.bool,
  withChip: PropTypes.bool,
  chip: PropTypes.shape(UIChip.propTypes),
};
