import React from 'react';
import cx from 'classnames';
import styles from './Switch.module.scss';
import { HTMLAttributes, forwardRef } from 'react';
import { useClassnames } from 'shared/useClassnames';

export type SwitchProps = Omit<HTMLAttributes<HTMLElement>, 'onChange'> & {
  value: boolean;
  onChange: (value: boolean) => void;
  InputProps?: Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;
  className?: string;
  classes?: typeof styles;
  disabled?: boolean;
};

export type SwitchRef = HTMLLabelElement;

const Switch = forwardRef<SwitchRef, SwitchProps>(
  (
    {
      value,
      onChange,
      className,
      classes = {},
      InputProps = {},
      disabled = false,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);
    return (
      <label
        ref={ref}
        className={cx(
          classNames.root,
          { [classNames.active]: value, [classNames.disabled]: disabled },
          className
        )}
        {...props}
      >
        <input
          type="checkbox"
          checked={value}
          value={value.toString()}
          disabled={disabled}
          onChange={() => onChange(!value)}
          {...InputProps}
        />
        <div className={classNames.element}>
          <div className={classNames.background} />
          <div className={classNames.dot}></div>
        </div>
      </label>
    );
  }
);

Switch.displayName = 'Switch';

export default Switch;
