import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useList } from "react-use";
import PropTypes from "prop-types";
import { IEvent } from "../../types/event";
import UIButton from "../Button/Button";
import AddImage from "../../icons/AddImage";
import Delete from "../../icons/Delete";
import Send from "../../icons/Send";
import UIInput from "../Input/Input";
import UIForm from "../Form/Form";
import Typography from "../Typography/Typography";

const File = ({ onDelete, source }) => {
  return (
    <div className="UIFeedback-file" onClick={onDelete}>
      <img src={source} alt="" />
      <div className="UIFeedback-file-overlay" />
      <Delete />
    </div>
  );
};

const Files = ({
  value,
  event,
  onChange,
  errorMessage,
  name,
  fileMaxSizeMb,
  filesMaxLength,
}) => {
  const [fileList, fileActions] = useList([]);
  const [error, setError] = useState(false);
  const inputRef = useRef();

  const handleChange = (e) => {
    const { files } = e.target;
    if (files.length === 0 || fileList.length === filesMaxLength) return;
    Array.from(files).forEach(handleImageChange);
  };

  const createImageThumbnail = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (file) => {
    if (fileList.length === filesMaxLength) return;
    if (file.size / 1e6 > fileMaxSizeMb) {
      setError(true);
      return;
    }
    setError(false);
    const image = await createImageThumbnail(file);
    fileActions.push({ file, image, id: uuidv4() });
  };

  const handleDelete = (i) => {
    fileActions.removeAt(i);
    inputRef.current.value = "";
  };

  useEffect(() => {
    onChange(
      name,
      [...fileList].map((e) => e.file)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (!value) {
      fileActions.reset();
      inputRef.current.value = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <div className="UIFeedback-files">
        <div className="UIFeedback-files-list">
          {fileList.map((file, i) => (
            <File
              key={file.id}
              source={file.image}
              onDelete={() => handleDelete(i)}
            />
          ))}
        </div>
        {error && (
          <Typography variant="caption" className="UIFeedback-error">
            {errorMessage}
          </Typography>
        )}
      </div>
      <label className="UIFeedback-addFileControl">
        <input
          type="file"
          accept="image/*"
          name={name}
          onChange={handleChange}
          ref={inputRef}
        />
        <UIButton
          size="small"
          appearance="ghost-dark"
          icon={<AddImage />}
          event={event}
          component="div"
          disabled={fileList.length === filesMaxLength}
        />
      </label>
    </>
  );
};

const Form = ({ onSubmit, children }) => (
  <form className="UIFeedback" onSubmit={onSubmit}>
    {children}
  </form>
);

const Body = ({ children }) => (
  <div className="UIFeedback-body">{children}</div>
);

const Bottom = ({ children, event, submitBtnAttrs }) => (
  <div className="UIFeedback-bottom">
    <UIForm.Group>
      <UIForm.Row>{children}</UIForm.Row>
      <UIButton
        appearance="primary"
        event={event}
        icon={<Send />}
        {...submitBtnAttrs}
      />
    </UIForm.Group>
  </div>
);

const Message = ({ onChange, event, placeholder, value, name, short }) => (
  <div className="UIFeedback-message">
    <UIInput
      onChange={onChange}
      value={value}
      event={event}
      name={name}
      placeholder={placeholder}
      attributes={{ rows: short ? 4 : 7 }}
      isTextarea
    />
  </div>
);

const Email = ({ onChange, event, label, value, name }) => (
  <UIInput
    onChange={onChange}
    event={event}
    name={name}
    label={label}
    value={value}
    type="email"
  />
);

Files.defaultProps = {
  name: "files",
  fileMaxSizeMb: 10,
  filesMaxLength: 4,
};

Files.propTypes = {
  event: PropTypes.shape(IEvent.propTypes).isRequired,
  fileMaxSizeMb: PropTypes.number,
  filesMaxLength: PropTypes.number,
  errorMessage: PropTypes.string.isRequired,
  value: PropTypes.array,
};

Bottom.defaultProps = {
  submitBtnAttrs: {},
};

Bottom.propTypes = {
  event: PropTypes.shape(IEvent.propTypes).isRequired,
  submitBtnAttrs: PropTypes.object,
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

Message.defaultProps = {
  name: "message",
};

Message.propTypes = {
  event: PropTypes.shape(IEvent.propTypes).isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  short: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

Email.defaultProps = {
  name: "email",
};

Email.propTypes = {
  event: PropTypes.shape(IEvent.propTypes).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

File.propTypes = {
  onDelete: PropTypes.func.isRequired,
  source: PropTypes.string,
};

export default {
  Email,
  Files,
  Message,
  Body,
  Bottom,
  Form,
};
