import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const UICircularProgress = ({ className, classes }) => {
  return (
    <svg
      className={cx("UICircularProgress", className, classes.root)}
      viewBox="22 22 44 44"
      width="22"
      height="22"
    >
      <circle
        className={cx("UICircularProgress-path", classes.path)}
        cx="44"
        cy="44"
        r="20.2"
        fill="none"
        strokeWidth="3.6"
      />
    </svg>
  );
};

UICircularProgress.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    path: PropTypes.string,
  }),
};

UICircularProgress.defaultProps = {
  classes: {},
};

export default UICircularProgress;
