import React, { useState } from "react";
import { usePopper } from "react-popper";
import cx from "classnames";

const UITooltipContent = ({ content, triggerEl, placement, visible }) => {
  const [tooltipEl, setTooltipEl] = useState(null);
  const { styles } = usePopper(triggerEl, tooltipEl, {
    placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <div
      className={cx("UITooltip-content", { "-visible": visible })}
      style={styles.popper}
      ref={setTooltipEl}
    >
      {content}
    </div>
  );
};

const UITooltip = ({ content, placement, children }) => {
  const [triggerEl, setTriggerEl] = useState(null);

  return (
    <div className="UITooltip">
      <div className="UITooltip-trigger" ref={setTriggerEl}>
        {children}
      </div>
      <UITooltipContent
        content={content}
        placement={placement}
        triggerEl={triggerEl}
      />
    </div>
  );
};

UITooltip.Content = UITooltipContent;

export default UITooltip;
