import Menu from 'components/Menu';
import { PopupProps } from 'components/Popup';
import TextField, {
  InputAttributes,
  TextFieldProps,
  TextFieldRef,
} from 'components/TextField';
import React, {
  forwardRef,
  ReactNode,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import styles from './Select.module.scss';
import cx from 'classnames';
import ArrowIcon from '../../icons/triangle-arrow.svg';
import { SelectContext, SelectProvider } from './context';
import { useClassnames } from 'shared/useClassnames';

type MultipleProps =
  | {
      multiple?: false;
      value?: any;
    }
  | {
      multiple: true;
      value?: Array<any>;
    };

export type SelectProps = MultipleProps & {
  className?: string;
  onChange?: (value: any) => void;
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
  InputProps?: Partial<InputAttributes>;
  inputRef?: Ref<TextFieldRef>;
  MenuProps?: Partial<PopupProps>;
  renderValue?: (value: any) => ReactNode | string;
  children?: ReactNode;
  opened?: boolean;
  onOpenedChange?: (value: boolean) => void;
  closeOnChange?: boolean;
  classes?: typeof styles;
};

const SelectContent = forwardRef<HTMLDivElement, SelectProps>(
  (
    {
      renderValue,
      label,
      TextFieldProps = {},
      InputProps = {},
      inputRef,
      children,
      MenuProps = {},
      className,
      classes = {},
    },
    ref
  ) => {
    const {
      selected,
      onItemClick,
      opened,
      onOpenedChange: handleOpenedChange,
    } = useContext(SelectContext);
    const textFieldRef = useRef<HTMLDivElement>(null);

    const classNames = useClassnames(styles, classes);

    useImperativeHandle(inputRef, () => {
      return textFieldRef.current as HTMLDivElement;
    });

    const renderedValue = useMemo(() => {
      if (renderValue) return renderValue(selected);
      if (Array.isArray(selected)) return selected.join(', ');
      if (!selected) return '';
      return selected.toString();
    }, [selected, renderValue]);

    const childrenArray = React.Children.toArray(children);

    const items = childrenArray.map(child => {
      if (!React.isValidElement(child)) return null;

      if (child.props.value === undefined) return child;

      return React.cloneElement(child as React.ReactElement<any>, {
        onClick: () => onItemClick(child),
        selected: Array.isArray(selected)
          ? selected.includes(child.props.value)
          : selected === child.props.value,
      });
    });

    return (
      <div ref={ref} className={cx(classNames.root, className)}>
        <TextField
          value={renderedValue}
          InputProps={{ readOnly: true, ...InputProps }}
          label={label}
          ref={textFieldRef}
          classes={{
            input: classNames.textfield,
            endIcon: cx(classNames.icon, { [classNames.iconOpened]: opened }),
          }}
          RootProps={{
            onClick: () => handleOpenedChange(true),
          }}
          endIcon={<ArrowIcon />}
          {...TextFieldProps}
        />
        <Menu
          opened={opened}
          onClose={() => handleOpenedChange(false)}
          anchorEl={textFieldRef.current}
          {...MenuProps}
        >
          {items}
        </Menu>
      </div>
    );
  }
);

const Select = forwardRef<HTMLDivElement, SelectProps>((props, ref) => (
  <SelectProvider {...props}>
    <SelectContent {...props} ref={ref} />
  </SelectProvider>
));

SelectContent.displayName = 'SelectContent';

Select.displayName = 'Select';

export default Select;
