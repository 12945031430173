import React, { HTMLAttributes, ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import * as Icons from 'icons';
import styles from './FavoriteButton.module.scss';
import { useClassnames } from 'shared/useClassnames';

export type FavoriteButtonProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  variant?:
    | 'primary'
    | 'secondary'
    | 'ghost-dark'
    | 'ghost-light'
    | 'transparent'
    | 'overlay';
  inactiveIcon?: ReactNode;
  activeIcon?: ReactNode;
  onChange?: (value: boolean) => void;
  value?: boolean;
  classes?: typeof styles;
  className?: string;
  children?: ReactNode;
};

const FavoriteButton = forwardRef<any, FavoriteButtonProps>(
  (
    {
      variant = 'secondary',
      inactiveIcon = <Icons.FavoriteOutlineIcon />,
      activeIcon = <Icons.FavoriteIcon />,
      onChange = () => {},
      value = false,
      classes = {},
      children,
      className,
      ...props
    },
    ref
  ) => {
    const classNames = useClassnames(styles, classes);

    return (
      <div
        ref={ref}
        className={cx(
          classNames.root,
          {
            [classNames.primary]: variant === 'primary',
            [classNames.ghostLight]: variant === 'ghost-light',
            [classNames.overlay]: variant === 'overlay',
            [classNames.ghostDark]: variant === 'ghost-dark',
            [classNames.transparent]: variant === 'transparent',
            [classNames.secondary]: variant === 'secondary',
            [classNames.withChildren]: children,
            [classNames.active]: value,
          },
          className
        )}
        {...props}
        onClick={() => onChange(!value)}
      >
        <div className={classNames.icon}>
          {inactiveIcon}
          {activeIcon}
        </div>
        {children && <div className={classNames.content}>{children}</div>}
      </div>
    );
  }
);

FavoriteButton.displayName = 'FavoriteButton';

export default FavoriteButton;
