import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from 'types/polymorphic';
import React from 'react';
import cx from 'classnames';
import { useClassnames } from 'shared/useClassnames';
import styles from './Badge.module.scss';

export type BadgeProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      color?: 'orange' | 'black';
      content?: string;
      classes?: typeof styles;
    }
  >;

type DEFAULT_COMPONENT = 'div';
const DEFAULT_COMPONENT = 'div';

export type BadgeComponent = <C extends React.ElementType = DEFAULT_COMPONENT>(
  props: BadgeProps<C>
) => React.ReactElement | null;

// eslint-disable-next-line react/display-name
const Badge: BadgeComponent = React.forwardRef(
  <C extends React.ElementType = DEFAULT_COMPONENT>(
    {
      component,
      className,
      classes = {},
      content,
      color = 'black',
      ...props
    }: BadgeProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Root = component || DEFAULT_COMPONENT;
    const classNames = useClassnames(styles, classes);

    if (!content || content.length === 0) return null;

    return (
      <Root
        className={cx(
          classNames.root,
          {
            [classNames.black]: color === 'black',
            [classNames.orange]: color === 'orange',
            [classNames.round]: content.length === 1,
          },
          className
        )}
        ref={ref}
        {...props}
      >
        {content}
      </Root>
    );
  }
);

export default Badge;
