import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from 'types/polymorphic';
import React, { ReactNode } from 'react';
import cx from 'classnames';
import CircularProgress from '../../CircularProgress';
import styles from './ChipRefresh.module.scss';
import { useClassnames } from 'shared/useClassnames';

export type ChipProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      disabled?: boolean;
      loading?: boolean;
      icon?: ReactNode;
      children?: ReactNode;
      active?: boolean;
      classes?: Partial<
        Record<'root' | 'content' | 'icon' | 'circularProgress', string>
      >;
    }
  >;

type DEFAULT_COMPONENT = 'button';
const DEFAULT_COMPONENT = 'button';

export type ChipComponent = <C extends React.ElementType = DEFAULT_COMPONENT>(
  props: ChipProps<C>
) => React.ReactElement | null;

// eslint-disable-next-line react/display-name
const Chip: ChipComponent = React.forwardRef(
  <C extends React.ElementType = DEFAULT_COMPONENT>(
    {
      component,
      className,
      disabled = false,
      loading = false,
      icon,
      classes = {},
      children,
      active = false,
      onClick,
      ...props
    }: ChipProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Root = component || DEFAULT_COMPONENT;
    const classNames = useClassnames(styles, classes);
    return (
      <Root
        className={cx(
          classNames.root,
          {
            [classNames.active]: active,
            [classNames.loading]: loading,
            [classNames.disabled]: disabled,
            [classNames.hover]: Boolean(onClick) && !disabled,
          },
          className
        )}
        disabled={disabled}
        ref={ref}
        onClick={onClick}
        {...props}
      >
        <div className={classNames.content}>{children}</div>
        {icon && <div className={classNames.icon}>{icon}</div>}
        {loading && (
          <CircularProgress className={classNames.circularProgress} size={24} />
        )}
      </Root>
    );
  }
);

export default Chip;
